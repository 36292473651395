import { Global } from '@emotion/react';
import { useIsMutating, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useFeatureFlags } from '../../../../api/feature-flags';
import { useMutateAnswers, usePage } from '../../../../api/page';
import { usePublicConfig } from '../../../../api/publicConfig';
import recaptchaService from '../../../../api/recaptcha/recaptcha-service';
import { useMutateAuth, useMutateSession, useSession } from '../../../../api/session';
import { SEGMENT } from '../../../../constants/analytics';
import QUERY_CACHE_KEYS from '../../../../constants/query-cache-keys';
import useRecaptchaVerify from '../../../../hooks/use-recaptcha-verify';
import { useSegment } from '../../../../hooks/use-segment';
import QuestionPageHeading from '../../../../questionsForm/components/QuestionPageHeading/QuestionPageHeading';
import QuestionFormLoading from '../../../../questionsForm/components/QuestionsFormLoading/QuestionFormLoading';
import SectionAuthForm from '../../../../questionsForm/components/SectionForm/SectionAuthForm/SectionAuthForm';
import { getAuthFormQuestionKeys } from '../../../../questionsForm/components/SectionForm/SectionAuthForm/SectionAuthForm.util';
import { CUSTOM_CONTAINERS_KEYS } from '../../../../questionsForm/components/SectionForm/SectionForm.util';
import { scrollToError } from '../../../../questionsForm/utils/questions-form.util';
import noop from '../../../../shared/utils/noop';
import { globalFormCSS } from '../../../../styles/global.style';
import { PageTypes } from '../../../../types/page.type';

import {
  getCurrentNavigationPage,
  getCurrentSectionKey,
  getNextNavigationKeys,
} from '../../../../utils/current-form-keys.util';
import { getPathByPageType } from '../../../../utils/route-path.util';
import { hasAuthSection, isAuthSection } from '../../../../utils/schema.util';
import { useQuestionsFormState } from '../../contexts/questions-context';
import { setQuestionsKeys } from '../../contexts/questions-context/actions';
import QuestionFormContent from '../QuestionFormContent/QuestionFormContent';
import RecaptchaModal from './../../../../components/RecaptchaModal/RecaptchaModal';
import {
  getQuestionsKeysFromSections,
  getAnswersByKeys,
  getVisibleSections,
  prepareAnswers,
} from './QuestionsForm.util';
import type { QuestionsFormProps } from './QuestionsForm.type';
import type { Answers } from '../../../../types/answer.type';
import type { PageResponse } from '../../../../types/page.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { WorkflowSectionSchema } from '../../../../types/section.type';
import type { SessionResponse } from '../../../../types/session.type';
import type { FC } from 'react';

const QuestionsForm: FC<QuestionsFormProps> = ({ customCSS }) => {
  const navigate = useNavigate();
  const { gid, flow } = useParams() as DefaultLocation;
  const { data: configData } = usePublicConfig();
  const { data: sessionData } = useSession(gid);
  const { isLoading: isFFLoading } = useFeatureFlags(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const { data: pageData } = usePage<WorkflowSectionSchema>(gid, currentPageKey);
  const currentSectionKey = getCurrentSectionKey(sessionData);
  const visibleSections = getVisibleSections(pageData?.page.sections ?? [], currentSectionKey);
  const recaptchaSiteKey = configData?.config.integrations.recaptcha?.site_key_v2;

  const { mutateAsync: mutateAnswers } = useMutateAnswers(gid);
  const { mutateAsync: mutateSession } = useMutateSession(gid);

  const { mutateAsync: mutateAuth } = useMutateAuth(gid);
  const [showRecaptchaModal, setShowRecaptchaModal] = useState(false);
  const queryClient = useQueryClient();
  const previousPageKey = useRef<undefined | string>(undefined);
  const { handleSubmit, getValues } = useFormContext();
  const { track, page } = useSegment();
  const isQueryClientMutating = useIsMutating();
  const customAfterRecaptchaSubmit = useRef<() => void>(noop);
  const isRecaptchaVerified = useRef<boolean>(false);
  const { verifyRecaptcha } = useRecaptchaVerify();

  const { dispatch } = useQuestionsFormState();

  const [showAuthError, setShowAuthError] = useState<boolean>(false);
  const showAuth = hasAuthSection(visibleSections) && isAuthSection(visibleSections[0]);
  const authSectionSchema = visibleSections[0];

  const [isRedirectLater, setIsRedirectLater] = useState(false);

  useEffect(() => {
    if (pageData?.page.sections && !showAuth) {
      const questionsKeys = getQuestionsKeysFromSections(pageData.page.sections);
      dispatch(setQuestionsKeys(questionsKeys));
    }
  }, [pageData, showAuth, dispatch]);

  useEffect(() => {
    if (currentPageKey && currentPageKey !== previousPageKey.current) {
      page(currentPageKey);
      previousPageKey.current = currentPageKey;
    }
  }, [currentPageKey, page]);

  const checkNextPage = useCallback(
    (updatedSessionData: SessionResponse): void => {
      const nextCurrentPage = getCurrentNavigationPage(updatedSessionData);

      if (currentPageKey !== updatedSessionData.session.current_page_key) {
        track(SEGMENT.events.pageCompleted, {
          page: currentPageKey,
        });
      }

      if (nextCurrentPage && nextCurrentPage.page_type !== PageTypes.QuestionForm) {
        navigate(getPathByPageType(nextCurrentPage?.page_type, gid, flow), { replace: true });
      }
    },
    [currentPageKey, flow, gid, navigate, track]
  );

  const updateSessionKeys = useCallback(async (): Promise<SessionResponse> => {
    const pageData = queryClient.getQueryData<PageResponse<WorkflowSectionSchema>>([
      QUERY_CACHE_KEYS.page,
      gid,
      currentPageKey,
    ]);
    const { pageKey, sectionKey } = getNextNavigationKeys(sessionData as SessionResponse, pageData?.answers);
    const newSessionData = await mutateSession({
      current_page_key: pageKey,
      current_section_key: sectionKey,
      completed_page_key: currentPageKey,
    });
    queryClient.setQueryData([QUERY_CACHE_KEYS.session, gid], newSessionData);

    return newSessionData;
  }, [gid, mutateSession, queryClient, sessionData, currentPageKey]);

  const prepareToTheNextPage = useCallback(async (): Promise<void> => {
    setIsRedirectLater(false);

    const newSessionData = await updateSessionKeys();

    checkNextPage(newSessionData);
  }, [checkNextPage, updateSessionKeys]);

  useEffect(() => {
    if (isRedirectLater && isQueryClientMutating > 0) {
      prepareToTheNextPage();
    }
  }, [isQueryClientMutating, isRedirectLater, prepareToTheNextPage]);

  const onAuthSubmit = async (data: Answers): Promise<void> => {
    setShowAuthError(false);

    track(SEGMENT.events.answersSubmitted, {
      section_key: currentSectionKey,
    });

    const dataToUpdate = getAnswersByKeys(data, getAuthFormQuestionKeys());

    try {
      await mutateAuth(dataToUpdate);
      const newSessionData = await updateSessionKeys();
      await queryClient.refetchQueries({
        queryKey: [QUERY_CACHE_KEYS.page, gid, newSessionData.session.current_page_key],
      });
    } catch (error) {
      setShowAuthError(true);
      await queryClient.refetchQueries({ queryKey: [QUERY_CACHE_KEYS.session, gid] });
    }
  };

  const onContainerSubmit = async (currentContainerKey: string): Promise<void> => {
    if (CUSTOM_CONTAINERS_KEYS.includes(currentContainerKey)) {
      // containers with custom forms doesn't have answers for submit
      return;
    }

    const data = getValues();

    track(SEGMENT.events.answersSubmitted, {
      section_key: currentSectionKey,
    });

    const dataToUpdate = prepareAnswers(data, visibleSections, currentContainerKey);
    await mutateAnswers(dataToUpdate);
    isRecaptchaVerified.current = false;
  };

  const onSubmit = async (data: Answers): Promise<void> => {
    const shouldShowRecaptchaPuzzle = await verifyRecaptcha(currentPageKey ?? '');

    if (!isRecaptchaVerified.current && shouldShowRecaptchaPuzzle) {
      setShowRecaptchaModal(true);
      track(SEGMENT.events.recaptchaPuzzleSeen);
      customAfterRecaptchaSubmit.current = () => onSubmit(data);
    } else {
      track(SEGMENT.events.answersSubmitted, {
        section_key: currentSectionKey,
      });

      const dataToUpdate = prepareAnswers(data, visibleSections);
      const { answers } = await mutateAnswers(dataToUpdate);
      isRecaptchaVerified.current = false;
      queryClient.setQueryData([QUERY_CACHE_KEYS.page, gid, currentPageKey], {
        ...pageData,
        answers,
      });

      if (isQueryClientMutating > 0) {
        setIsRedirectLater(true);
        return;
      }

      await prepareToTheNextPage();
    }
  };

  const onRecaptchaModalSubmit = async (token: string | null): Promise<void> => {
    setShowRecaptchaModal(false);

    track(SEGMENT.events.successfullyRecaptchaComplete);
    token &&
      (await recaptchaService.updateRecaptchaSolvePuzzle(gid, {
        recaptcha_token: token,
      }));

    isRecaptchaVerified.current = true;
    customAfterRecaptchaSubmit.current();
  };

  if (!pageData || isFFLoading) {
    return (
      <div css={customCSS}>
        <QuestionFormLoading />
      </div>
    );
  }

  return (
    <div css={customCSS}>
      <Global styles={globalFormCSS} />
      {pageData?.page.content_heading && (
        <QuestionPageHeading
          heading={pageData.page.content_heading}
          description={pageData.page.content_description ?? ''}
          icon={pageData.page.content_icon_url ?? ''}
        />
      )}
      {recaptchaSiteKey && (
        <RecaptchaModal
          isOpen={showRecaptchaModal}
          onVisibilityChange={onRecaptchaModalSubmit}
          siteKey={recaptchaSiteKey}
        />
      )}

      {showAuth ? (
        <SectionAuthForm
          section={authSectionSchema}
          showError={showAuthError}
          onSubmit={handleSubmit(onAuthSubmit, scrollToError)}
        />
      ) : (
        <QuestionFormContent
          onSubmitContainerByClick={onContainerSubmit}
          onSubmit={handleSubmit(onSubmit, scrollToError)}
        />
      )}
    </div>
  );
};

export default QuestionsForm;
