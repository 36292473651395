import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import theme from '../../../../styles/theme';
import { text14CSS } from '../../../../styles/typography.style';
import { activeElementResetCSS } from '../../../shared.style';
import type { SerializedStyles } from '@emotion/react';

export const contentCSS = (rowDirection: boolean): SerializedStyles => css`
  display: flex;
  flex-direction: ${rowDirection ? 'row' : 'column'};
  align-items: ${rowDirection ? 'center' : 'flex-start'};
  justify-content: flex-start;
  overflow: hidden;
`;

export const dotCSS = (isClosed: boolean): SerializedStyles => css`
  top: ${isClosed ? 29 : 16}px;
  left: 20px;
  transition: top 0.3s ease;
`;

export const toggleCSS = (isClosed: boolean): SerializedStyles => css`
  ${activeElementResetCSS};
  display: flex;
  position: absolute;
  right: 20px;
  top: ${isClosed ? 31 : 16}px;
  color: ${theme.colors.gray60};
  transform: rotate(${isClosed ? 180 : 0}deg);
  transition: top 0.3s ease;
  &:before {
    content: '';
    position: absolute;
    left: -10px;
    right: -10px;
    top: -10px;
    bottom: -10px;
  }
`;

export const otherCSS = (isClosed: boolean): SerializedStyles => css`
  display: flex;
  align-items: center;
  height: ${isClosed ? 39 : 120}px;
  margin-left: ${isClosed ? 32 : 0}px;

  img {
    width: ${isClosed ? 39 : 72}px;
  }
`;

export const photoCSS = (url: string, isClosed: boolean): SerializedStyles => css`
  flex-shrink: 0;
  width: ${isClosed ? '46px' : '100%'};
  height: ${isClosed ? 39 : 120}px;
  margin-left: ${isClosed ? 32 : 0}px;
  margin-top: ${isClosed ? 0 : 32}px;
  background-image: url(${url});
  background-position: center;
  background-size: cover;
  transition:
    margin-top 0.6s ease-in,
    margin-left 0.2s ease-in,
    width 0.2s ease-in,
    height 0.2s ease-in;

  ${mq[0]} {
    margin-top: 0;
  }
`;

export const labelContainerCSS = (isClosed: boolean): SerializedStyles => css`
  width: 100%;
  margin-top: ${isClosed ? 0 : 16}px;
  margin-left: ${isClosed ? 12 : 0}px;
  margin-right: ${isClosed ? 28 : 0}px;
`;

export const nestedQuestionsCSS = css`
  margin-top: 24px;
  ${mq[0]} {
    margin-top: 12px;
  }
`;

export const labelCSS = css`
  font-weight: 700;
`;

export const descriptionCSS = (isClosed: boolean): SerializedStyles => css`
  margin-top: ${isClosed ? 4 : 8}px;
  height: ${isClosed ? '20px' : 'auto'};
  ${text14CSS};
  line-height: 20px;
  color: ${theme.colors.gray60};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const checkboxIconCSS = css`
  height: 64px;
  opacity: 0.5;
  transition: opacity 0.2s ease;
  z-index: 1;
`;

export const markIconCSS = (isClosed: boolean): SerializedStyles => css`
  top: ${isClosed ? 'calc(50% - 10px)' : '16px'};
  transition: top 0.2s ease-in;

  ${mq[0]} {
    top: 16px;
  }
`;
