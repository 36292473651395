import { css } from '@emotion/react';
import { formInputFocusOutlineCSS } from '../../../styles/form.style';
import { mq } from '../../../styles/media-queries';
import theme from '../../../styles/theme';
import { text14CSS } from '../../../styles/typography.style';
import { checkboxIconHoveredCSS } from './CheckboxIcon/CheckboxIcon.style';
import type { CheckboxVariant } from './Checkbox.type';
import type { SerializedStyles } from '@emotion/react';

const CHECK_ICON_WIDTH = 20;

export const containerHoverCSS = (checked?: boolean, hasError?: boolean): SerializedStyles => css`
  border-color: ${checked ? theme.colors.azure50 : theme.colors.azure62};

  & > .checkbox-input-mark {
    ${checkboxIconHoveredCSS(hasError)};
  }
`;

const verticalContainerCSS = css`
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  padding: 16px;
`;

const simpleCheckboxCSS = css`
  border: none;
  padding: 0 15px;
  margin-top: 16px;
  box-shadow: none;
  &:hover {
    border: none;
  }
  &:before {
    content: none;
  }
`;

const defaultCheckboxCSS = (hasSubElements: boolean): SerializedStyles => css`
  ${hasSubElements &&
  css`
    padding-left: 17px;
  `}
`;

const photoGridCheckboxCSS = css`
  padding: 16px 20px;
  ${mq[0]} {
    padding: 56px 20px 20px 20px;
  }
`;

const getCheckboxVariantStyles = (variant: CheckboxVariant, hasSubElements: boolean): SerializedStyles => {
  switch (variant) {
    case 'vertical':
    case 'narrow':
      return verticalContainerCSS;
    case 'simple':
      return simpleCheckboxCSS;
    case 'photoGrid':
      return photoGridCheckboxCSS;
    default:
      return defaultCheckboxCSS(hasSubElements);
  }
};

export const containerCSS = (
  disabled = false,
  variant: CheckboxVariant = 'default',
  hasSubElements = false,
  checked = false,
  hasError = false
): SerializedStyles => css`
  position: relative;
  display: flex;
  flex-wrap: ${hasSubElements ? 'wrap' : 'nowrap'};
  align-items: center;
  justify-content: space-between;
  cursor: ${disabled ? 'auto' : 'pointer'};
  user-select: none;
  border: 1px solid ${checked ? theme.colors.azure50 : theme.colors.black};
  box-shadow: inset 0 0 0 1px ${checked ? theme.colors.azure50 : 'transparent'};
  padding: 20px 16px 20px ${16 + CHECK_ICON_WIDTH}px;
  border-radius: ${theme.sizes.borderRadius}px;
  box-sizing: border-box;
  height: 100%;

  &:before {
    ${formInputFocusOutlineCSS};
  }

  &:hover {
    ${!disabled && containerHoverCSS(checked, hasError)};
  }

  ${getCheckboxVariantStyles(variant, hasSubElements)};
`;

export const checkboxCSS = css`
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;

  &:focus + div {
    box-shadow: unset;
    border-color: ${theme.colors.black};

    &:before {
      border-color: ${theme.colors.azure50};
    }
  }
`;

const verticalCheckIconCSS = (hasSubElements: boolean): SerializedStyles => css`
  position: relative;
  left: 0;
  top: 0;
  margin-bottom: 16px;
  ${hasSubElements &&
  css`
    ${mq[0]} {
      top: calc(10px - ${CHECK_ICON_WIDTH / 2}px);
    }
  `}
`;

const simpleCheckIconCSS = css`
  left: 0;
`;

const defaultCheckIconCSS = (hasSubElements: boolean, hasIcon: boolean): SerializedStyles => css`
  ${hasSubElements &&
  css`
    top: calc(${hasIcon ? 40 : 30}px - ${CHECK_ICON_WIDTH / 2}px);

    ${mq[0]} {
      top: calc(${hasIcon ? 40 : 30}px - ${CHECK_ICON_WIDTH / 2}px);
    }
  `}
`;

const photoGridCheckIconCSS = css`
  top: 16px;
`;

const getCheckboxIconVariantStyles = (
  variant: CheckboxVariant,
  hasSubElements: boolean,
  hasIcon: boolean
): SerializedStyles => {
  switch (variant) {
    case 'vertical':
    case 'narrow':
      return verticalCheckIconCSS(hasSubElements);
    case 'simple':
      return simpleCheckIconCSS;
    case 'photoGrid':
      return photoGridCheckIconCSS;
    default:
      return defaultCheckIconCSS(hasSubElements, hasIcon);
  }
};

export const checkIconCSS = (
  hasError = false,
  variant: CheckboxVariant = 'default',
  hasSubElements = false,
  hasIcon = false,
  isClosed?: boolean
): SerializedStyles => css`
  position: absolute;
  left: 16px;
  top: calc(${hasSubElements ? 25 : 50}% - ${CHECK_ICON_WIDTH / 2}px);
  ${hasError &&
  css`
    border-color: ${theme.colors.errorRed};
  `};

  ${getCheckboxIconVariantStyles(variant, hasSubElements, hasIcon)};
  ${isClosed !== undefined && applyClosedCSS(isClosed)};
`;

export const applyClosedCSS = (isClosed: boolean): SerializedStyles => css`
  top: ${isClosed ? `calc(50% - ${CHECK_ICON_WIDTH / 2}px)}` : '16px'};
  transition: top 0.2s ease-in;

  ${mq[0]} {
    top: 16px;
  }
`;

export const labelContainerCSS = (
  hasSubElements = false,
  variant: CheckboxVariant = 'default'
): SerializedStyles => css`
  margin-left: 16px;
  z-index: 2;
  ${hasSubElements &&
  css`
    flex-basis: 50%;
    flex-grow: 1;
    ${variant === 'default' &&
    css`
      margin-left: 30px;
    `}
  `}
`;

export const titleCSS = css`
  display: block;
  font-weight: 700;
`;

export const subtitleCSS = css`
  display: block;
  ${text14CSS};
  color: ${theme.colors.gray60};
  margin-top: 4px;
  ${mq[0]} {
    margin-top: 8px;
  }
`;

export const imageCSS = (checked: boolean, withMargin?: boolean): SerializedStyles => css`
  margin-left: ${withMargin ? 12 : 0}px;
  opacity: ${checked ? 1 : 0.4};
  transition: opacity 0.3s linear;
`;

export const subElementsWrapperCSS = css`
  width: 100%;
  margin-top: 24px;
  margin-bottom: -12px;
  margin-right: -16px;
`;

export const labelCSS = (variant: CheckboxVariant): SerializedStyles => css`
  ${variant === 'photoGrid' &&
  css`
    display: block;
    height: 100%;
  `}
`;
