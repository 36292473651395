import { useEffect, useState } from 'react';
import CheckIcon from '../../../../../assets/check-mark.svg?react';
import DoubleHousesWithClouds from '../../../../../assets/double-houses-with-clouds.svg';
import PersonIcon from '../../../../../assets/person-with-dollars.svg?react';
import PlantAndPhone from '../../../../../assets/plant-and-phone.svg';
import SeparatedHousesWithClouds from '../../../../../assets/separate-houses-with-clouds.svg';
import { SUBHEADERS } from '../../../../../components/QuotePageLoader/QuotePageLoader.util';
import SIRV_IMAGES from '../../../../../constants/sirv-images';
import InfoFormBlock from '../../../../../questionsForm/components/InfoFormBlock/InfoFormBlock';
import Button from '../../../../../shared/components/Button/Button';
import LoadingDots from '../../../../../shared/components/LoadingDots/LoadingDots';
import QuotePageCalendlyBlock from '../../../components/DigitalProfileQuotePage/QuotePageCalendlyBlock/QuotePageCalendlyBlock';
import {
  headerCSS,
  infoBlockCSS,
  subheaderCSS,
  containerCSS,
  headerBackgroundCSS,
  elipseCSS,
  separateHouseIconCSS,
  doubleHouseIconCSS,
  headerContentCSS,
  carriersSliderCSS,
  readyQuoteImgCSS,
  buttonCSS,
  checkIconCSS,
} from './DigitalProfileInterstitial.style';
import { HEADERS, INFO_BLOCK_CONTENT } from './DigitalProfileInterstitial.util';
import type { DigitalProfileInterstitialProps } from './DigitalProfileInterstitial.type';
import type { FC, ReactNode } from 'react';

const DigitalProfileInterstitial: FC<DigitalProfileInterstitialProps> = ({
  shouldShowBundleMessage = false,
  isShadowAgentRunningVariation = false,
  isShadowAgentFinished = false,
  isNoQuotesVariation = false,
  onSeeMyQuoteClickHandler,
}) => {
  const [index, setIndex] = useState(0);

  const getHeading = (): string | ReactNode => {
    switch (true) {
      case isNoQuotesVariation:
        return <span>We’re going to need to chat.</span>;
      case isShadowAgentFinished:
        return (
          <>
            <CheckIcon css={checkIconCSS} />
            <span>Your Quote is Ready!</span>
          </>
        );
      case isShadowAgentRunningVariation:
        return 'We’ll send your quotes soon';
      default:
        return HEADERS[index];
    }
  };

  const getSubHeading = (): string | ReactNode => {
    switch (true) {
      case isNoQuotesVariation:
        return (
          <span>
            Your home is unique and requires a specialist to find you a policy.
            <strong> A Matic agent will be reaching out soon</strong> to help you find a carrier who will cover the
            needs of your home.
          </span>
        );
      case isShadowAgentFinished:
        return 'Thank you for your patience. Your quote has been prepared and is now available.';
      case isShadowAgentRunningVariation:
        return (
          <span>
            Your home is special, so we need a little bit more time to look through our network of <b>40+ carriers. </b>
            Don’t worry, we’ll email and text you when your quotes are ready. This can take up to 9 mins.
          </span>
        );
      default:
        return SUBHEADERS[index];
    }
  };

  const scheduleCallHeading = 'Have a busy schedule?';
  const scheduleCallSubHeading =
    'When the quotes are ready, our agents will walk you through your quotes at the time convenient for you';

  const onSeeMyQuoteClick = (): any => {
    onSeeMyQuoteClickHandler && onSeeMyQuoteClickHandler();
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prevIndex) => {
        if (prevIndex === HEADERS.length - 1) {
          clearInterval(timer);
          return prevIndex;
        }
        return prevIndex + 1;
      });
    }, 15000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div css={containerCSS}>
      <div css={headerBackgroundCSS}>
        <img css={separateHouseIconCSS} src={SeparatedHousesWithClouds} alt="" />
        <div css={headerContentCSS}>
          {isShadowAgentFinished ? (
            isNoQuotesVariation ? (
              <img src={PlantAndPhone} css={readyQuoteImgCSS} alt="" />
            ) : (
              <PersonIcon css={readyQuoteImgCSS} />
            )
          ) : (
            <img
              css={carriersSliderCSS(isShadowAgentRunningVariation)}
              src={
                isShadowAgentRunningVariation
                  ? SIRV_IMAGES.carriersSACarouselSlider
                  : SIRV_IMAGES.carriersCarouselSlider
              }
              alt=""
            />
          )}
          <div css={headerCSS}> {getHeading()}</div>
          <div css={subheaderCSS}>{getSubHeading()}</div>
          <div css={elipseCSS} />
        </div>
        <img css={doubleHouseIconCSS} src={DoubleHousesWithClouds} alt="" />
      </div>
      {!isNoQuotesVariation &&
        (isShadowAgentFinished ? (
          <Button onClick={onSeeMyQuoteClick} css={buttonCSS}>
            See My Quote
          </Button>
        ) : (
          <LoadingDots />
        ))}

      {shouldShowBundleMessage && !isShadowAgentRunningVariation && (
        <InfoFormBlock customCSS={infoBlockCSS(false)} iconUrl={SIRV_IMAGES.infoIcon} content={INFO_BLOCK_CONTENT} />
      )}

      {(isShadowAgentRunningVariation || isShadowAgentFinished) && (
        <QuotePageCalendlyBlock
          heading={scheduleCallHeading}
          subHeading={scheduleCallSubHeading}
          isShadowAgentRunning
          isNoQuotesShadowAgentVariation={isNoQuotesVariation}
        />
      )}

      {shouldShowBundleMessage && isShadowAgentRunningVariation && (
        <InfoFormBlock customCSS={infoBlockCSS(true)} iconUrl={SIRV_IMAGES.infoIcon} content={INFO_BLOCK_CONTENT} />
      )}
    </div>
  );
};

export default DigitalProfileInterstitial;
