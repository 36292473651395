import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePublicFlowConfig } from '../../api/public/flow-config';
import { SEGMENT } from '../../constants/analytics';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { useSegment } from '../../hooks/use-segment';
import { ConfigType, StrategyType } from '../../types/public-flow-config.type';
import { isBundleQuotesGroupBindable } from '../../utils/quote.util';
import type { PageKey } from '../../types/page.type';
import type { QuoteRequest, QuoteType } from '../../types/quote.type';
import type { DefaultLocation } from '../../types/route-params.type';

interface ControlFlowConfigChangeProps<T> {
  quoteRequest: QuoteRequest<T> | null;
  currentPageKey?: PageKey;
}

const useDynamicFlowConfigChange = <T extends QuoteType>({
  quoteRequest,
  currentPageKey,
}: ControlFlowConfigChangeProps<T>): { isFinished: boolean } => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionFlowConfig } = usePublicFlowConfig(gid);
  const queryClient = useQueryClient();
  const { track } = useSegment();

  const [isCheckFinished, setIsCheckFinished] = useState(false);

  const cheapestQuotesGroup = quoteRequest?.groups?.[0];
  const homeQuote = cheapestQuotesGroup?.quotes?.[0];

  const isQuoteBindable = isBundleQuotesGroupBindable(cheapestQuotesGroup) || homeQuote?.bindable;

  useEffect(() => {
    const call = async (): Promise<void> => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.session, gid] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.sessionConfig, gid] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.publicFlowConfig, gid] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.carrier, gid] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.page, gid, currentPageKey] });
      setIsCheckFinished(true);
    };

    if (!quoteRequest || !sessionFlowConfig) {
      return;
    }

    if (
      isQuoteBindable &&
      quoteRequest.finished_at &&
      sessionFlowConfig.flow_config.strategy === StrategyType.DigitalProfile &&
      sessionFlowConfig.flow_config.config_type !== ConfigType.Dynamic
    ) {
      track(SEGMENT.events.bindableQuoteReceived);
      call();
      return;
    }

    setIsCheckFinished(true);
  }, [quoteRequest, sessionFlowConfig, isQuoteBindable, queryClient, gid, currentPageKey, track]);

  return { isFinished: isCheckFinished };
};

export default useDynamicFlowConfigChange;
