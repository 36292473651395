import type { SerializedStyles } from '@emotion/react';
import type { ReactNode } from 'react';

interface Tab {
  label: ReactNode;
  content: ReactNode;
}

export interface TabPanelProps {
  content: ReactNode;
  index: number;
  value: number;
}

export enum TabsVariant {
  RECTANGULAR = 'default',
  ROUNDED = 'rounded',
}

export interface TabsProps {
  customCSS?: SerializedStyles;
  variant?: TabsVariant;
  tabs: Tab[];
}
