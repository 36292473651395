import { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSession } from '../../api/session';
import { SEGMENT } from '../../constants/analytics';
import { getExperimentVariationName } from '../../utils/feature-flags.util';
import { useSegment } from '../use-segment';
import type { FeatureFlag } from '../../types/feature-flag.type';
import type { SectionContainer } from '../../types/section.type';

const useTrackFeatureCondition = (containers?: SectionContainer[], featureFlags?: FeatureFlag[]): void => {
  const { gid } = useParams();
  const { track } = useSegment();
  const tracked = useRef<boolean>(false);
  const { data: sessionData } = useSession(gid);

  const trackFeature = useCallback(
    (featureFlagName?: string): void => {
      if (!featureFlagName) return;

      const featureToggle = featureFlags?.find((ft) => ft.name === featureFlagName);
      if (featureToggle?.enabled && featureToggle.variant) {
        track(SEGMENT.events.experimentViewed, {
          experiment_id: featureToggle.name,
          variation_id: featureToggle.variant,
          gid: sessionData?.session.engagement_gid,
          variation_name: getExperimentVariationName(featureToggle.variant),
        });
      }
    },
    [featureFlags, track, sessionData]
  );

  useEffect(() => {
    if (!!containers?.length && !!featureFlags?.length && !tracked.current) {
      containers.forEach((container) => {
        trackFeature(container.feature_condition?.feature_flag);
        container.elements.forEach((e) => {
          trackFeature(e.feature_condition?.feature_flag);
        });
      });
      tracked.current = true;
    }
  }, [containers, featureFlags, trackFeature]);
};

export default useTrackFeatureCondition;
