import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelectedCarrier } from '../../../../../api/carrier';
import { usePage } from '../../../../../api/page';
import useEffectivePolicy from '../../../../../api/policy/use-effective-policy';
import { usePrimaryInsured } from '../../../../../api/primary-insured';
import { useCreateQuoteRequest, useInitialQuote, useLastQuoteRequest } from '../../../../../api/quote';
import { useMutateSession, useSession } from '../../../../../api/session';
import { useSessionFlowConfig } from '../../../../../api/session-flow-config';
import { SEGMENT } from '../../../../../constants/analytics';
import QUERY_CACHE_KEYS from '../../../../../constants/query-cache-keys';
import { useSegment } from '../../../../../hooks/use-segment';
import { Card } from '../../../../../shared/components/Card/Card';
import { innerLayoutContainerCSS } from '../../../../../styles/layout.style';
import { ConfigType } from '../../../../../types/public-flow-config.type';
import {
  getCurrentNavigationPage,
  getCurrentSectionKey,
  getNextNavigationKeys,
} from '../../../../../utils/current-form-keys.util';
import { getPrimaryQuote, getQuoteSavings } from '../../../../../utils/quote.util';
import { getPathByPageType } from '../../../../../utils/route-path.util';
import HomePolicyDetails from '../HomePolicyDetails/HomePolicyDetails';
import HomePolicyEditableDetails from '../HomePolicyEditableDetails/HomePolicyEditableDetails';
import HomeQuoteCoverages from '../HomeQuoteCoverages/HomeQuoteCoverages';
import { getPageHeadingText, QUOTE_PAGE_DISCLAIMER } from '../HomeQuoteOpportunityPage.util';
import HomeQuotePageHeading from '../HomeQuotePageHeading/HomeQuotePageHeading';
import QuoteOptionalCoverage from '../QuoteOptionalCoverage/QuoteOptionalCoverage';
import ViewAFQuotes from '../ViewAFQuotes/ViewAFQuotes';
import { cardFooterCSS, coveragesContainerCSS, disclaimerCSS, wrapperCSS } from './HomeQuotePageContent.style';
import type { HomeQuotePageContentProps } from './HomeQuotePageContent.type';
import type { Address } from '../../../../../types/address.type';
import type { Carrier } from '../../../../../types/carrier.type';
import type { HomeInitialQuote, HomeQuote } from '../../../../../types/quote.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { CustomSectionSchema } from '../../../../../types/section.type';
import type { SessionResponse } from '../../../../../types/session.type';
import type { FC } from 'react';

const HomeQuotePageContent: FC<HomeQuotePageContentProps> = ({ customCSS, quote }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { gid, flow } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);
  const currentPage = sessionData?.session.current_page_key ?? '';
  const { data: pageData } = usePage<CustomSectionSchema>(gid, currentPage);
  const currentPageKey = sessionData?.session.current_page_key;
  const { mutateAsync: mutateSession } = useMutateSession(gid);
  const { data: carrierData } = useSelectedCarrier<Carrier>(gid);
  const { data: effectivePolicy } = useEffectivePolicy(gid);
  const { data: initialQuoteData } = useInitialQuote<HomeInitialQuote>(gid);
  const { data: primaryInsuredData } = usePrimaryInsured(gid);
  const { data: sessionFlowConfig } = useSessionFlowConfig(gid);
  const { data: lastQuoteRequestData } = useLastQuoteRequest<HomeQuote>(gid);

  const { mutateAsync: createQuoteRequest } = useCreateQuoteRequest(gid);

  const { track } = useSegment();

  const savings = getQuoteSavings(quote, !!quote.multi_policy_discount_premium, effectivePolicy?.effective_policy);

  const shouldRenderPageWithAdditionalQuestions = sessionFlowConfig?.flow_config.config_type === ConfigType.Dynamic;
  const originalRC2Quote = getPrimaryQuote<HomeQuote>(
    lastQuoteRequestData?.quotes_request?.groups[0]?.quotes ?? [],
    sessionFlowConfig?.flow_config.policy_type,
    initialQuoteData?.quote?.carrier_key
  );

  const address = (pageData?.answers?.property_address as Address) || primaryInsuredData?.person.mailing_address;
  const pageHeading = getPageHeadingText(
    shouldRenderPageWithAdditionalQuestions,
    quote,
    initialQuoteData?.quote,
    originalRC2Quote,
    address
  );

  const isDynamicConfigType = sessionFlowConfig?.flow_config.config_type === ConfigType.Dynamic;

  useEffect(() => {
    if (quote.premium) {
      track(SEGMENT.events.finalQuoteViewed, {
        quotes: [quote.gid],
      });
    }
  }, [track, quote]);

  const onSubmit = async (): Promise<void> => {
    if (carrierData) {
      track(SEGMENT.events.quoteSelected, {
        ordinal: 1,
        carrier_key: carrierData?.carrier.key,
      });
    }

    const { pageKey, sectionKey } = getNextNavigationKeys(sessionData as SessionResponse);
    const newSessionData = await mutateSession({
      current_page_key: pageKey,
      current_section_key: sectionKey,
      completed_page_key: currentPageKey,
      completed_section_key: getCurrentSectionKey(sessionData as SessionResponse),
    });

    if (isDynamicConfigType) {
      await createQuoteRequest({});
      await queryClient.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.quoteRequest, gid] });
    }

    queryClient.setQueryData([QUERY_CACHE_KEYS.session, gid], newSessionData);

    const nextCurrentPage = getCurrentNavigationPage(newSessionData);
    track(SEGMENT.events.pageCompleted, {
      page: currentPageKey,
    });
    navigate(getPathByPageType(nextCurrentPage?.page_type, gid, flow), { replace: true });
  };

  return (
    <div css={customCSS}>
      <div css={[innerLayoutContainerCSS(false), wrapperCSS]}>
        <HomeQuotePageHeading heading={pageHeading.heading} subheading={pageHeading.subheading} />

        <div>
          <Card>
            {shouldRenderPageWithAdditionalQuestions ? (
              <HomePolicyEditableDetails quote={quote} onSubmit={onSubmit} />
            ) : (
              <HomePolicyDetails
                carrierLogo={carrierData?.carrier?.logo_url}
                premium={quote.premium}
                discounts={quote.discounts}
                savings={savings}
                effectiveDate={quote.policy_effective_date}
                onSubmit={onSubmit}
              />
            )}
            <div css={cardFooterCSS}>
              <HomeQuoteCoverages customCSS={coveragesContainerCSS} coverages={quote.coverages} />
              <QuoteOptionalCoverage />
            </div>
          </Card>
        </div>

        <div css={disclaimerCSS}>{QUOTE_PAGE_DISCLAIMER}</div>

        {!!savings && !shouldRenderPageWithAdditionalQuestions && <ViewAFQuotes />}
      </div>
    </div>
  );
};

export default HomeQuotePageContent;
