import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text14CSS, text20CSS, text24CSS, textCSS } from '../../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const policyCoveragesTypeCSS = css`
  display: flex;
  justify-content: start;
  height: 60px;
  background-color: ${theme.colors.gray5};
  padding: 0 24px;

  ${mq[0]} {
    padding: 0 40px;
    border-top-left-radius: ${theme.sizes.borderRadius}px;
    border-top-right-radius: ${theme.sizes.borderRadius}px;
  }
`;

export const buttonCSS = (isActive: boolean): SerializedStyles => css`
  background-color: transparent;
  border: none;
  color: ${theme.colors.black};
  border-radius: 0;
  padding: 0;
  font-weight: 500;

  &:first-of-type {
    margin-right: 31px;
  }

  &:active,
  &:focus,
  &:hover {
    background-color: inherit;
    color: inherit;
  }

  span {
    display: flex;
    align-items: center;
  }

  ${isActive &&
  css`
    color: ${theme.colors.azure50};
    box-shadow: inset 0 -5px 0 -1px ${theme.colors.azure50};

    font-weight: 700;
    &:active,
    &:focus,
    &:hover {
      background-color: inherit;
      color: ${theme.colors.azure50};
    }
  `}
`;

export const coveragesContainerCSS = css`
  padding: 20px 24px 32px;

  ${mq[0]} {
    padding: 16px 40px 48px;
  }
`;

export const tabsContainerCSS = css`
  margin-top: 12px;
  ${mq[0]} {
    margin-top: 16px;
  }
`;

export const smallIconCSS = css`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

export const addressCSS = css`
  margin-bottom: 8px;
  color: ${theme.colors.gray60};
  font-weight: 500;
  ${text14CSS};

  ${mq[0]} {
    ${textCSS};
  }
`;

export const deductibleContainerCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 24px 24px 0;

  ${mq[0]} {
    flex-wrap: nowrap;
    padding: 32px 40px 8px;
  }
`;

export const deductibleLabelCSS = css`
  ${text20CSS};
  font-weight: 700;
  ${mq[0]} {
    ${text24CSS};
  }
`;
export const deductibleDescriptionCSS = css`
  ${text14CSS};
  color: ${theme.colors.gray60};
  padding-top: 6px;
  width: 80%;

  ${mq[0]} {
    width: 100%;
  }
`;

export const deductibleCSS = css`
  margin-top: 16px;
  min-width: 140px;
  margin-bottom: 12px;

  ${mq[0]} {
    min-width: 132px;
    margin-left: 40px;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const optionalCoveragesWrapperCSS = css`
  margin-top: 32px;

  ${mq[0]} {
    margin-top: 40px;
  }
`;

export const optionalTitleCSS = css`
  ${text20CSS};
  font-weight: 700;
  margin-bottom: 10px;
  border: unset;
  background-color: transparent;
  padding: 0;

  ${mq[0]} {
    ${text24CSS};
  }
`;

export const optionalDescriptionCSS = css`
  ${text14CSS};
  color: ${theme.colors.gray60};
  margin-bottom: 24px;
`;
