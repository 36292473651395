export interface FeatureFlagsResponse {
  feature_flags: FeatureFlag[];
}
export interface FeatureFlag {
  name: string;
  enabled: boolean;
  variant: ExperimentVariation | null;
}

export enum ExperimentVariation {
  CONTROL_VARIATION = 'control_variation',
  TEST_VARIATION_A = 'test_variation_a',
  TEST_VARIATION_B = 'test_variation_b',
}

export type ExperimentReportVariantsId = '0' | '1' | '2';

export enum ExperimentVariationName {
  CONTROL = 'Control',
  TEST = 'Test',
}
