import { useQueryClient } from '@tanstack/react-query';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePage } from '../../../../../api/page';
import { usePublicConfig } from '../../../../../api/publicConfig';
import {
  useCreateQuoteRequest,
  useLastQuoteRequest,
  useMutateQuote,
  useQuoteRequestsSubscription,
} from '../../../../../api/quote';
import { useSession } from '../../../../../api/session';
import { useSessionFlowConfig } from '../../../../../api/session-flow-config';
import QUERY_CACHE_KEYS from '../../../../../constants/query-cache-keys';
import QuestionLabel from '../../../../../questionsForm/components/QuestionWrapper/QuestionLabel/QuestionLabel';
import Select from '../../../../../shared/components/Select/Select';
import SkeletonLoader from '../../../../../shared/components/SkeletonLoader/SkeletonLoader';
import { getPrimaryQuote } from '../../../../../utils/quote.util';
import { getDeductibleComponent, isDeductibleComponent } from '../../../../../utils/schema.util';
import WaitingLoadingModal from '../WaitingLoadingModal/WaitingLoadingModal';
import { containerCSS, labelCSS, loadingLabelCSS } from './DeductibleControl.style';
import type { DeductibleControlProps } from './DeductibleControl.type';
import type { DeductibleSchema } from '../../../../../types/form-component.type';
import type { HomeQuote } from '../../../../../types/quote.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { FC } from 'react';

const DeductibleControl: FC<DeductibleControlProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionFlowConfig } = useSessionFlowConfig(gid);
  const { data: configData } = usePublicConfig();
  const { data: sessionData } = useSession(gid);
  const { data: pageData } = usePage(gid, sessionData?.session.current_page_key);
  const [schema, setSchema] = useState<DeductibleSchema>();

  const { data: quoteRequestData } = useLastQuoteRequest<HomeQuote>(gid);

  const quotes = quoteRequestData?.quotes_request?.groups[0]?.quotes;

  const quote = getPrimaryQuote<HomeQuote>(
    quotes,
    sessionFlowConfig?.flow_config.policy_type,
    sessionFlowConfig?.flow_config?.carrier_key
  );
  const { mutateAsync: updateQuote } = useMutateQuote(gid);
  const { mutateAsync: createRequest } = useCreateQuoteRequest(gid);

  const webSocketUrl = configData?.config.websocket_url;
  const { isFinished, startSubscription } = useQuoteRequestsSubscription({ gid, webSocketUrl });

  const queryClient = useQueryClient();
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    const call = async (): Promise<void> => {
      await queryClient.refetchQueries({ queryKey: [QUERY_CACHE_KEYS.quoteRequest, gid] });

      // Refetching the session to update session lock timer
      await queryClient.refetchQueries({ queryKey: [QUERY_CACHE_KEYS.session, gid] });
      setIsUpdating(false);
    };

    if (isUpdating && isFinished) {
      call();
    }
  }, [isUpdating, isFinished, queryClient, gid]);

  const onChange = async (value: unknown): Promise<void> => {
    if (value === quote?.deductible) {
      return;
    }
    setIsUpdating(true);

    await updateQuote({ [schema?.key as string]: value as string });
    startSubscription();
    await createRequest({});
  };

  useEffect(() => {
    if (pageData?.page) {
      const component = getDeductibleComponent(pageData.page.sections);
      if (isDeductibleComponent(component)) {
        setSchema(component);
      } else {
        setSchema(undefined);
      }
    }
  }, [pageData]);

  if (!pageData || !quote) {
    return (
      <div css={[containerCSS, customCSS?.container]}>
        <SkeletonLoader absoluteHeight={20} width={40} customCSS={loadingLabelCSS} />
        <SkeletonLoader absoluteHeight={44} width={100} />
      </div>
    );
  }

  return (
    <div css={[containerCSS, customCSS?.container]}>
      {!schema ? (
        <Fragment>
          <div css={[labelCSS, customCSS?.label]}>Deductible</div>
          {quote.deductible}
        </Fragment>
      ) : (
        <Fragment>
          <QuestionLabel customCSS={[labelCSS, customCSS?.label]} keyName={schema.key}>
            {schema.label}
          </QuestionLabel>
          <Select
            customCSS={customCSS?.input}
            inputId={schema.key}
            options={schema.variants}
            placeholder={schema.placeholder}
            value={quote.deductible}
            dense
            onValidEntry={onChange}
          />
          <WaitingLoadingModal
            title="Calculating new price..."
            description="This might take up to 30 seconds"
            open={isUpdating}
          />
        </Fragment>
      )}
    </div>
  );
};

export default DeductibleControl;
