import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';

export const containerCSS = css`
  display: flex;
  flex-wrap: wrap;

  ${mq[0]} {
    margin-right: -16px;
    margin-bottom: -12px;
  }
`;

export const selectCSS = css`
  display: none;
`;
