import { useQuery } from '@tanstack/react-query';
import QUERY_CACHE_KEYS from '../../../constants/query-cache-keys';
import publicSessionService from './public-session.service';
import type { PublicSessionResponse } from '../../../types/public-session.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const usePublicSession = (gid?: string): QueryObserverResult<PublicSessionResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.publicSession, gid],
    queryFn: gid ? async () => await publicSessionService.getPublicSession(gid) : undefined,
    enabled: Boolean(gid),
  });

export default usePublicSession;
