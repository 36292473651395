import api from '../../../api/api';
import API_PATH from '../../../constants/api';
import { getApiUrl } from '../../../utils/api.util';
import type { HTTPResponse } from '../../../types/api.type';
import type {
  PublicFlowConfigResponse,
  PublicPrimaryInsuredResponse,
  PublicSessionResponse,
} from '../../../types/public-session.type';

const publicSessionService = {
  async getPublicSession(gid: string): Promise<PublicSessionResponse> {
    return await api
      .get<HTTPResponse<PublicSessionResponse>>(getApiUrl(API_PATH.public.session, gid))
      .then((res) => res.data);
  },
  async getPublicPrimaryInsured(gid: string): Promise<PublicPrimaryInsuredResponse> {
    return await api
      .get<HTTPResponse<PublicPrimaryInsuredResponse>>(getApiUrl(API_PATH.public.primaryInsured, gid))
      .then((res) => res.data);
  },
  async getPublicFlowConfig(gid: string): Promise<PublicFlowConfigResponse> {
    return await api
      .get<HTTPResponse<PublicFlowConfigResponse>>(getApiUrl(API_PATH.public.flowConfig, gid))
      .then((res) => res.data);
  },
};

export default publicSessionService;
