import { useQuery } from '@tanstack/react-query';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { sessionService } from './index';
import type { SessionResponse } from '../../types/session.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const useSession = (gid?: string): QueryObserverResult<SessionResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.session, gid],
    queryFn: gid ? async () => await sessionService.getSession(gid) : undefined,
    enabled: Boolean(gid),
  });
export default useSession;
