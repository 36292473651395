import { css } from '@emotion/react';

export const verticalTitleContainerCSS = css`
  display: flex;
  align-items: center;
`;

export const verticalTitleCSS = css`
  margin-left: 12px;
`;

export const verticalSubtitleCSS = css`
  margin-top: 16px;
`;
