import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { PolicyType } from '../types/policy.type';
import { Flows, type DefaultLocation } from '../types/route-params.type';
import analytics from '../utils/analytics.util';

interface UseSegmentType {
  track: (eventName: string, config?: Record<string, unknown>) => void;
  page: (pageName: string, config?: Record<string, unknown>) => void;
}

export const useSegment = (): UseSegmentType => {
  const { gid, flow } = useParams() as DefaultLocation;

  const track = useCallback(
    (eventName: string, config: Record<string, unknown> = {}): void => {
      // https://maticinsurance.atlassian.net/browse/OLB-1479
      const analyticFlow = flow === Flows.Auto ? PolicyType.Auto : PolicyType.Home;
      analytics.track(eventName, gid, analyticFlow, config);
    },
    [flow, gid]
  );

  const page = useCallback(
    (pageName: string, config?: Record<string, unknown>): void =>
      analytics.page(pageName, {
        session_gid: gid,
        ...config,
      }),
    [gid]
  );

  return {
    track,
    page,
  };
};
