import QuoteCoverageGroup from '../../QuoteCoverageGroup/QuoteCoverageGroup';
import { generateCoveragesGroup } from './DigitalProfileQuoteCoverages.util';
import type { DigitalProfileQuoteCoveragesProps } from './DigitalProfileQuoteCoverages.type';
import type { FC } from 'react';

const DigitalProfileQuoteCoverages: FC<DigitalProfileQuoteCoveragesProps> = ({
  customCSS,
  coverages,
  groupTitle = 'Your Coverages',
  hideTitle = false,
}) => {
  const groups = generateCoveragesGroup(coverages, groupTitle, hideTitle);

  return (
    <div css={customCSS}>
      {groups.map((group) => (
        <QuoteCoverageGroup key={group.assetGid} group={group} isExpandable={false} />
      ))}
    </div>
  );
};

export default DigitalProfileQuoteCoverages;
