import type { Answers } from './answer.type';
import type { FeatureCondition } from './feature_condition.type';
import type { QuestionType, QuestionVariant } from './question.type';
import type { Validation } from './validation.type';
import type { VisibilityCondition } from './visibility-condition.type';

export enum FormElementType {
  TextBox = 'text_box',
  Question = 'question',
  InfoBlock = 'info_block',
  Disclosure = 'disclosure',
  LoanForm = 'loan_form',
  CoInsuredForm = 'co_insured_form',
}

export interface TextElementContent {
  heading: string;
  subheading?: string;
  description?: string;
  heading_icon_url?: string | null;
  block_icon_url?: string | null;
}

export interface QuestionElementContent {
  key: string;
  type: QuestionType;
  label: string;
  placeholder?: string;
  icon?: string;
  description?: string;
  hint?: string;
  variants?: QuestionVariant[];
  validations?: Validation[];
  alt_label?: string | null;
}

export interface InfoBlockContent {
  key: string;
  icon_url: string;
  info_block_text: string;
}

export interface DisclosureContent {
  disclosure_gid: string;
  text: string;
}

export type GridWidth = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export enum FormElementTag {
  HideLabel = 'hide_label',
  NavigationPoint = 'navigation_point',
  QuestionsGroupLabel = 'questions_group_label',
  CheckboxVertical = 'checkbox_vertical',
  RadioList = 'radio_list',
  RadioPhotoGrid = 'radio_photo_grid',
  WrappedInCheckbox = 'wrapped_in_checkbox',
  TextBoxIconSmall = 'text_box_icon_small',
  InfoBlockWarning = 'info_block_warning',
  SimpleCheckbox = 'simple_checkbox',
  DatePicker = 'date_picker',
  Heading = 'heading',
  ReportViewed = 'report_viewed',
  TrackForbidden = 'track_forbidden',
  ForbidSendNullAnswer = 'forbid_send_null_answer',
  RenderDropDownInBody = 'render_dropdown_in_body',
  ShowOtherOptionAlways = 'show_other_option_always',
  MultiSelectCheckbox = 'multi_select_checkbox',
  CheckboxPhotoGrid = 'checkbox_photo_grid',
}

export interface FormElementSchema<
  T = TextElementContent | QuestionElementContent | InfoBlockContent | DisclosureContent,
> {
  key: string;
  kind: FormElementType;
  position: number;
  width: GridWidth;
  content: T;
  tags: FormElementTag[];
  visibility_conditions: VisibilityCondition[][];
  feature_condition: FeatureCondition | null;
  sub_elements: FormElementSchema[];
}

export interface GetElementByTypeArguments {
  element: FormElementSchema;
  isFirstContainerOnPage?: boolean;
  enableScrollAnimation?: boolean;
  isSubElement?: boolean;
  context?: Array<string | null>;
  answers?: Answers;
}
