import { useCallback, useEffect, useMemo, useState, type FC, type ReactNode } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import useTrackFeatureCondition from '../../../hooks/use-track-feature-condition/use-track-feature-condition';
import noop, { noopAsync } from '../../../shared/utils/noop';
import { isObjectEmpty } from '../../../shared/utils/object-helpers.util';
import ContainerForm from '../ContainerForm/ContainerForm';
import QuestionFormActionButton from '../QuestionFormActionButton/QuestionFormActionButton';
import { containerCSS, scrollPointCSS } from './SectionForm.style';
import {
  getAllContainers,
  getLastContainerIndex,
  getNextVisibleContainer,
  shouldGoToNextContainer,
} from './SectionForm.util';
import type { RenderSectionContainer, SectionFormProps } from './SectionForm.type';
import type { Answers } from '../../../types/answer.type';

const SectionForm: FC<SectionFormProps> = ({
  sectionKey,
  actionLabel,
  containers,
  isFirst,
  isCurrent,
  isLastWorkflowSection,
  renderAllContainersAtOnce = false,
  onSubmitContainerByClick = noop,
  onSubmit = noopAsync,
  featureFlags = [],
}) => {
  const {
    trigger,
    formState: { defaultValues: defaultAnswers },
  } = useFormContext<Answers>();

  const pageAnswers = useWatch();

  const [currentContainerKey, setCurrentContainerKey] = useState<string>('');
  const [allContainers, setAllContainers] = useState<RenderSectionContainer[]>([]);
  const [triggerSubmitCurrentQuestionKey, setTriggerSubmitCurrentQuestionKey] = useState<string | null>(null);

  useTrackFeatureCondition(containers, featureFlags);

  useEffect(() => {
    if (containers?.length && !isObjectEmpty(pageAnswers)) {
      const all = getAllContainers(containers, pageAnswers, featureFlags, defaultAnswers as unknown as Answers);
      const allVisible = all.filter((c) => c.isAllowRender);

      let key = currentContainerKey || allVisible[0].key;

      if (shouldGoToNextContainer(pageAnswers, triggerSubmitCurrentQuestionKey)) {
        const currentContainerIndex = allVisible.reduce((acc, container, index) => {
          if (container.key === key) {
            return index;
          }
          return acc;
        }, -1);
        key = allVisible[currentContainerIndex + 1].key;
        setTriggerSubmitCurrentQuestionKey(null);
      }

      const currentContainer = all.find((container) => container.key === key);

      if (!currentContainer) {
        key = allVisible[0].key;
      } else if (!currentContainer.isAllowRender) {
        const newVisibleContainer = getNextVisibleContainer(all, currentContainer.key);
        key = newVisibleContainer?.key || allVisible[0].key;
      }

      setCurrentContainerKey(key);
      setAllContainers(allVisible);
    }
  }, [
    containers,
    defaultAnswers,
    pageAnswers,
    currentContainerKey,
    triggerSubmitCurrentQuestionKey,
    setAllContainers,
    setTriggerSubmitCurrentQuestionKey,
    featureFlags,
  ]);

  const allContainersKeys = useMemo(() => allContainers.map((c) => c.key), [allContainers]);

  const lastContainerIndex = getLastContainerIndex(allContainersKeys, currentContainerKey);

  const containersToRender = allContainers.slice(0, lastContainerIndex + 1);

  const handleContainerSubmitByClick = async (): Promise<void> => {
    const isValid = await trigger();

    if (!isValid || !currentContainerKey) {
      return;
    }

    const currentContainerIndex = allContainers.findIndex((container) => container.key === currentContainerKey) || 0;
    const nextContainer = allContainers[currentContainerIndex + 1];

    setCurrentContainerKey(nextContainer.key);

    onSubmitContainerByClick(currentContainerKey);
  };

  const handleContainerSubmitByAnswer = useCallback(async (triggerQuestionKey: string): Promise<void> => {
    setTriggerSubmitCurrentQuestionKey(triggerQuestionKey);
  }, []);

  const renderContainers = (containers: RenderSectionContainer[]): ReactNode[] =>
    containers
      .filter((c) => c.isAllowRender)
      .map((c, index) => {
        const isFirstContainerOnPage = isFirst && index === 0;
        const isLastContainerOnPage = isLastWorkflowSection && index === containers.length - 1;
        return (
          <ContainerForm
            key={c.key}
            containerKey={c.key}
            visibleElements={c.elements}
            isFirstContainerOnPage={isFirstContainerOnPage}
            isLastContainerOnPage={isLastContainerOnPage}
            isLineHidden={isLastContainerOnPage}
            autoScrollEnabled={!isFirstContainerOnPage && isLastContainerOnPage && isCurrent}
            answers={pageAnswers}
          />
        );
      });

  return (
    <div css={containerCSS}>
      <div css={scrollPointCSS} id={`section-${sectionKey}`} />
      {isCurrent && !renderAllContainersAtOnce ? (
        <>
          {renderContainers(containersToRender)}
          <QuestionFormActionButton
            containers={allContainers}
            currentContainerKey={currentContainerKey}
            lastContainerButtonLabel={actionLabel}
            onContainerByAnswerSubmit={handleContainerSubmitByAnswer}
            onContainerByClickSubmit={handleContainerSubmitByClick}
            onLastContainerSubmit={onSubmit}
          />
        </>
      ) : (
        renderContainers(allContainers)
      )}
    </div>
  );
};

export default SectionForm;
