import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePage } from '../../../../../api/page';
import { useSession } from '../../../../../api/session';
import HouseIconSmall from '../../../../../assets/home-simple.svg?react';
import CarIconSmall from '../../../../../assets/vehicle.svg?react';

import { getDefaultValueString } from '../../../../../questionsForm/components/Question/AddressQuestion/Address.util';
import SelectQuestion from '../../../../../questionsForm/components/Question/SelectQuestion/SelectQuestion';
import Button from '../../../../../shared/components/Button/Button';
import Tabs from '../../../../../shared/components/Tabs/Tabs';
import { type AutoQuoteCoverage, type QuoteAsset } from '../../../../../types/quote.type';
import { getCoveragesGroups } from '../../../../../utils/coverages.util';
import {
  getDeductibleComponent,
  getOptionalCoverageComponents,
  isDeductibleComponent,
} from '../../../../../utils/schema.util';
import isVisibleByAnswers from '../../../../../utils/visibility-conditions.util';
import DigitalProfileQuoteCoverages from '../../DigitalProfileQuotePage/DigitalProfileQuoteCoverages/DigitalProfileQuoteCoverages';
import OptionalCoverageForm from '../OptionalCoverageForm/OptionalCoverageForm';
import { descriptionCSS, titleCSS } from '../QuoteOptionalCoverage/QuoteOptionalCoverage.style';
import {
  addressCSS,
  buttonCSS,
  coveragesContainerCSS,
  deductibleContainerCSS,
  deductibleCSS,
  deductibleDescriptionCSS,
  deductibleLabelCSS,
  policyCoveragesTypeCSS,
  smallIconCSS,
  tabsContainerCSS,
  optionalCoveragesWrapperCSS,
  optionalTitleCSS,
  optionalDescriptionCSS,
} from './BundleQuoteCoverages.style';
import { TabActiveType } from './BundleQuoteCoverages.type';
import type { BundleQuoteCoveragesProps } from './BundleQuoteCoverages.type';
import type { DeductibleSchema } from '../../../../../types/form-component.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import type { FC, ReactNode } from 'react';

const BundleQuoteCoverages: FC<BundleQuoteCoveragesProps> = ({ homeQuote, autoQuote, address }) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);
  const { data: pageData } = usePage(gid, sessionData?.session.current_page_key);

  const [activeType, setActiveType] = useState<TabActiveType>(TabActiveType.HOME);
  const [deductibleComponent, setDeductibleComponent] = useState<DeductibleSchema>();

  const optionalCoverageComponents =
    useMemo(
      () =>
        pageData &&
        getOptionalCoverageComponents(pageData.page.sections).filter((c) =>
          isVisibleByAnswers(pageData.answers, c.visibility_conditions)
        ),
      [pageData]
    ) ?? [];

  useEffect(() => {
    if (pageData?.page) {
      const component = getDeductibleComponent(pageData.page.sections);
      if (isDeductibleComponent(component)) {
        setDeductibleComponent(component);
      } else {
        setDeductibleComponent(undefined);
      }
    }
  }, [pageData]);

  const getAutoCoveragesTabs = (
    coverages: AutoQuoteCoverage[],
    assets: QuoteAsset[]
  ): Array<{ label: ReactNode; content: ReactNode }> => {
    const coverageGroups = getCoveragesGroups(coverages, assets);

    return coverageGroups.map((cg) => ({
      label: cg.title,
      content: (
        <DigitalProfileQuoteCoverages
          hideTitle
          coverages={cg.coverages.map((c) => ({
            ...c,
            key: cg.assetGid,
            asset_gid: cg.assetGid,
          }))}
        />
      ),
    }));
  };

  const getCoveragesToRender = (): EmotionJSX.Element => {
    switch (activeType) {
      case TabActiveType.AUTO:
        return (
          <Tabs
            customCSS={tabsContainerCSS}
            tabs={getAutoCoveragesTabs(autoQuote.coverages, autoQuote?.assets ?? [])}
          />
        );
      default:
        return <DigitalProfileQuoteCoverages coverages={homeQuote.coverages} groupTitle={coverageGroupTitle} />;
    }
  };

  const getOptionalCoveragesToRender = (): EmotionJSX.Element => {
    switch (activeType) {
      case TabActiveType.AUTO:
        return (
          <div>
            <div css={titleCSS}>Additional auto coverages available through agency</div>
            <div css={descriptionCSS}>
              Speak with a licensed Matic advisor to explore additional auto coverages and find the right protection if
              you want more coverage.
            </div>
          </div>
        );
      default:
        return (
          <>
            <div css={optionalTitleCSS}>Home additional coverages</div>
            <div css={optionalDescriptionCSS}>
              Below are some additions you can choose. For further details, and to add other optional coverages, talk to
              one of our agents after you finish purchasing a policy.
            </div>
            <OptionalCoverageForm
              answers={pageData?.answers ?? {}}
              components={optionalCoverageComponents ?? []}
              coverages={homeQuote.coverages}
            />
          </>
        );
    }
  };

  const coverageGroupTitle = (
    <div>
      <div>Home Coverages</div>
      <div css={addressCSS}>{getDefaultValueString(address)}</div>
    </div>
  );

  return (
    <div>
      <div css={policyCoveragesTypeCSS}>
        <Button
          customCSS={[buttonCSS(activeType === TabActiveType.HOME)]}
          onClick={() => setActiveType(TabActiveType.HOME)}
        >
          <HouseIconSmall css={smallIconCSS} />
          Home Coverages
        </Button>
        <Button
          customCSS={[buttonCSS(activeType === TabActiveType.AUTO)]}
          onClick={() => setActiveType(TabActiveType.AUTO)}
        >
          <CarIconSmall css={smallIconCSS} />
          Auto Coverages
        </Button>
      </div>
      {deductibleComponent && activeType === TabActiveType.HOME && (
        <div css={deductibleContainerCSS}>
          <div>
            <div css={deductibleLabelCSS}>{deductibleComponent.label}</div>
            <div css={deductibleDescriptionCSS}>{deductibleComponent.description}</div>
          </div>
          <div css={deductibleCSS}>
            <SelectQuestion
              dense
              questionKey={deductibleComponent.key}
              label={deductibleComponent.label}
              variants={deductibleComponent.variants}
              hideLabel
            />
          </div>
        </div>
      )}

      <div css={coveragesContainerCSS}>
        {getCoveragesToRender()}
        <div css={optionalCoveragesWrapperCSS}> {getOptionalCoveragesToRender()}</div>
      </div>
    </div>
  );
};

export default BundleQuoteCoverages;
