import { useEffect, type FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { usePage } from '../../../../../api/page';
import useEffectivePolicy from '../../../../../api/policy/use-effective-policy';
import { usePrimaryInsured } from '../../../../../api/primary-insured';
import { useInitialQuote, useLastQuoteRequest } from '../../../../../api/quote';
import { useSession } from '../../../../../api/session';
import { useSessionFlowConfig } from '../../../../../api/session-flow-config';
import { prepareCustomAnswers } from '../../../../../questionsForm/utils/answers/custom-schema-answers.util';
import { Card } from '../../../../../shared/components/Card/Card';
import { innerLayoutContainerCSS } from '../../../../../styles/layout.style';
import { PolicyType } from '../../../../../types/policy.type';
import { ConfigType } from '../../../../../types/public-flow-config.type';
import {
  HOME_POLICY_TYPES,
  type AutoQuote,
  type DigitalProfileQuote,
  type HomeInitialQuote,
  type HomeQuote,
} from '../../../../../types/quote.type';
import { getPrimaryQuote } from '../../../../../utils/quote.util';
import {
  getAutoPolicyEffectiveDateComponent,
  getDeductibleComponent,
  getHomePolicyEffectiveDateComponent,
} from '../../../../../utils/schema.util';
import BundleQuoteDetails from '../../BundleQuoteDetails/BundleQuoteDetails';
import BundleQuoteCoverages from '../BundleQuoteCoverages/BundleQuoteCoverages';
import { getPageHeadingText, QUOTE_PAGE_DISCLAIMER } from '../HomeQuoteOpportunityPage.util';
import HomeQuotePageHeading from '../HomeQuotePageHeading/HomeQuotePageHeading';
import { containerCSS, cardCSS, disclaimerCSS, wrapperCSS } from './BundleQuotePageContent.style';
import type { BundleQuotePageContentProps } from './BundleQuotePageContent.type';
import type { Address } from '../../../../../types/address.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { CustomSectionSchema } from '../../../../../types/section.type';

const BundleQuotePageContent: FC<BundleQuotePageContentProps> = ({ quotesGroup, customCSS }) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: initialQuoteData } = useInitialQuote<HomeInitialQuote>(gid);
  const { data: lastQuoteRequestData } = useLastQuoteRequest<HomeQuote>(gid);
  const { data: sessionFlowConfig } = useSessionFlowConfig(gid);
  const { data: sessionData } = useSession(gid);
  const currentPage = sessionData?.session.current_page_key ?? '';
  const { data: pageData } = usePage<CustomSectionSchema>(gid, currentPage);
  const { data: primaryInsuredData } = usePrimaryInsured(gid);
  const { data: initialQuote } = useInitialQuote<DigitalProfileQuote>(gid);
  const { data: homeEffectivePolicy } = useEffectivePolicy(gid);

  const quotes = quotesGroup?.quotes;
  const homeQuote = quotes?.find((q) => HOME_POLICY_TYPES.includes(q.policy_type)) as HomeQuote;
  const autoQuote = quotes?.find((q) => q.policy_type === PolicyType.Auto) as AutoQuote;
  const originalHomeRC2Quote = getPrimaryQuote<HomeQuote>(
    lastQuoteRequestData?.quotes_request?.groups[0]?.quotes ?? [],
    sessionFlowConfig?.flow_config.policy_type,
    initialQuoteData?.quote?.carrier_key
  );

  const address = (pageData?.answers?.property_address as Address) || primaryInsuredData?.person.mailing_address;

  const pageHeading = getPageHeadingText(
    sessionFlowConfig?.flow_config.config_type === ConfigType.Dynamic,
    homeQuote,
    initialQuoteData?.quote,
    originalHomeRC2Quote,
    address
  );

  const methods = useForm();

  useEffect(() => {
    if (pageData?.answers) {
      const deductibleComponent = getDeductibleComponent(pageData.page.sections);
      const homeEffectiveDateComponent = getHomePolicyEffectiveDateComponent(pageData.page.sections);
      const autoEffectiveDateComponent = getAutoPolicyEffectiveDateComponent(pageData.page.sections);
      const defaultAnswers = prepareCustomAnswers(pageData);

      if (deductibleComponent && homeEffectiveDateComponent && autoEffectiveDateComponent) {
        defaultAnswers[deductibleComponent.key] = homeQuote.deductible;
        defaultAnswers[homeEffectiveDateComponent.key] = homeQuote.policy_effective_date ?? '';
        defaultAnswers[autoEffectiveDateComponent?.key] = autoQuote.policy_effective_date ?? '';
      }

      methods.reset(defaultAnswers, { keepDirty: true, keepTouched: true });
    }
  }, [methods, pageData, homeQuote, autoQuote]);

  // TODO do some magic here
  const onFinalizeCheckoutClicked = (): void => {};

  // const handleSubmitCallback = (answers: Answers): void => {
  //   console.log('submitting form', answers);
  // };

  // TODO discuss loading state
  if (!quotes || !initialQuote || !homeEffectivePolicy || !pageData) {
    return <div>loading</div>;
  }

  return (
    <div css={[containerCSS, customCSS]}>
      <div css={[innerLayoutContainerCSS(false), wrapperCSS]}>
        <HomeQuotePageHeading heading={pageHeading.heading} subheading={pageHeading.subheading} />
        <div>
          <Card customCSS={cardCSS}>
            <FormProvider {...methods}>
              <form id="quote-details-form">
                <BundleQuoteDetails
                  sections={pageData?.page?.sections}
                  homeEffectivePolicy={homeEffectivePolicy.effective_policy}
                  quotes={quotes}
                  quotesGroup={quotesGroup}
                  onFinalizeCheckoutClicked={onFinalizeCheckoutClicked}
                />
                <BundleQuoteCoverages autoQuote={autoQuote} homeQuote={homeQuote} address={address} />
                {/* {import.meta.env.DEV && <DevTool control={methods.control} />} */}
              </form>
            </FormProvider>
          </Card>
        </div>
        <div css={disclaimerCSS}>{QUOTE_PAGE_DISCLAIMER}</div>
      </div>
    </div>
  );
};

export default BundleQuotePageContent;
