import type { PolicyType } from './policy.type';

export enum StrategyType {
  DigitalProfile = 'digital_profile',
  OnlineBind = 'online_bind',
}

export enum PaymentMethod {
  escrow = 'escrow',
  direct_bill = 'direct_bill',
}

export enum ConfigType {
  Standard = 'standard',
  Dynamic = 'dynamic',
}

export interface PublicFlowConfigResponse {
  flow_config: {
    payment_methods: PaymentMethod[];
    policy_type: PolicyType;
    state: string;
    carrier_key: string;
    strategy: StrategyType;
    config_type: ConfigType;
  };
}
