import { ExperimentVariation, ExperimentVariationName } from '../types/feature-flag.type';
import type { FeatureFlag, ExperimentReportVariantsId } from '../types/feature-flag.type';

export const EXPERIMENT_REPORT_VARIANTS: Record<ExperimentVariation, ExperimentReportVariantsId> = {
  [ExperimentVariation.CONTROL_VARIATION]: '0',
  [ExperimentVariation.TEST_VARIATION_A]: '1',
  [ExperimentVariation.TEST_VARIATION_B]: '2',
};

export const getFeatureFlagConfig = (
  featureFlags: FeatureFlag[] = [],
  name: string
): {
  name: string | undefined;
  enabled: boolean;
  variant: ExperimentVariation | null | undefined;
} => {
  const featureFlag = featureFlags.find((featureFlag) => featureFlag.name === name);

  return { enabled: !!featureFlag?.enabled, variant: featureFlag?.variant, name: featureFlag?.name };
};

export const getExperimentVariationName = (variant: ExperimentVariation): ExperimentVariationName => {
  return variant === ExperimentVariation.CONTROL_VARIATION
    ? ExperimentVariationName.CONTROL
    : ExperimentVariationName.TEST;
};
