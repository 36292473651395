import { Global } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { usePublicConfig } from '../../../../api/publicConfig';
import { useInitQuoteRequestSubscription } from '../../../../api/quote';
import { useSessionFlowConfig } from '../../../../api/session-flow-config';
import QuotePageLoader from '../../../../components/QuotePageLoader/QuotePageLoader';
import SIRV_IMAGES from '../../../../constants/sirv-images';
import { hideFooterCSS } from '../../../../shared/shared.style';
import { getPrimaryQuote, isBundleQuotesGroup } from '../../../../utils/quote.util';
import BundleQuotePageContent from './BundleQuotePageContent/BundleQuotePageContent';
import { loaderCSS, loaderImageCSS } from './HomeQuoteOpportunityPage.style';
import HomeQuotePageContent from './HomeQuotePageContent/HomeQuotePageContent';
import type { HomeQuotePageProps } from './HomeQuoteOpportunityPage.type';
import type { HomeQuote } from '../../../../types/quote.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { FC } from 'react';

const HomeQuotePage: FC<HomeQuotePageProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionFlowConfig, isPending: isSessionFlowConfigLoading } = useSessionFlowConfig(gid);
  const { data: configData } = usePublicConfig();
  const webSocketUrl = configData?.config.websocket_url;

  const { quoteRequest } = useInitQuoteRequestSubscription<HomeQuote>({
    gid,
    webSocketUrl,
  });

  const quotesGroup = quoteRequest?.groups[0];
  const homeQuote = getPrimaryQuote<HomeQuote>(
    quotesGroup?.quotes,
    sessionFlowConfig?.flow_config.policy_type,
    sessionFlowConfig?.flow_config?.carrier_key
  );

  if (!quoteRequest?.finished_at || !quotesGroup || !homeQuote || isSessionFlowConfigLoading) {
    return (
      <>
        <Global styles={hideFooterCSS} />
        <QuotePageLoader customCSS={loaderCSS} customImageCSS={loaderImageCSS} imgUrl={SIRV_IMAGES.homeLoader} />
      </>
    );
  }

  return (
    <div css={customCSS}>
      {isBundleQuotesGroup(quotesGroup) ? (
        <BundleQuotePageContent quotesGroup={quotesGroup} />
      ) : (
        <HomeQuotePageContent quote={homeQuote} />
      )}
    </div>
  );
};

export default HomeQuotePage;
