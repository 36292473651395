import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateQuoteRequest, useLastQuoteRequest } from '../../api/quote';
import { useSession } from '../../api/session';
import { SEGMENT } from '../../constants/analytics';
import useCustomerJourneyRedirect from '../../hooks/use-customer-journey-redirect';
import usePhoneNumber from '../../hooks/use-phone-number';
import { useSegment } from '../../hooks/use-segment';
import CallAgentRibbon from '../../pages/quote/components/DigitalProfileQuotePage/CallAgentRibbon/CallAgentRibbon';
import DigitalProfileNoQuotesContent from '../../pages/quote/components/DigitalProfileQuotePage/DigitalProfileNoQuotesContent/DigitalProfileNoQuotesContent';
import { NoQuoteMessageReason } from '../../pages/quote/components/DigitalProfileQuotePage/DigitalProfileQuotePage.util';
import { containerCSS } from './PolicyHolderDigitalProfileQuotePage.style';
import type { DigitalProfileQuote } from '../../types/quote.type';
import type { DefaultLocation } from '../../types/route-params.type';
import type { FC } from 'react';

const PolicyHolderDigitalProfileQuotePage: FC = () => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const { mutateAsync: createRequest } = useCreateQuoteRequest(gid);
  const {
    data: lastQuoteRequestData,
    isFetched: isLastQuoteRequestFetched,
    refetch: refetchLastQuoteRequest,
  } = useLastQuoteRequest<DigitalProfileQuote>(gid);
  const { phoneNumber } = usePhoneNumber();

  const { track } = useSegment();
  const { redirectAccordingToCustomerJourney, isCustomerJourneyFetched, nextStepUrl } = useCustomerJourneyRedirect(gid);

  useEffect(() => {
    const call = async (): Promise<void> => {
      await createRequest({});
      refetchLastQuoteRequest();
    };
    if (isLastQuoteRequestFetched && lastQuoteRequestData && !lastQuoteRequestData.quotes_request) {
      call();
    }
  }, [lastQuoteRequestData, createRequest, isLastQuoteRequestFetched, refetchLastQuoteRequest]);

  useEffect(() => {
    if (isCustomerJourneyFetched && lastQuoteRequestData?.quotes_request && nextStepUrl) {
      redirectAccordingToCustomerJourney();
    }
  }, [isCustomerJourneyFetched, lastQuoteRequestData, nextStepUrl, redirectAccordingToCustomerJourney]);

  const onPhoneClicked = (): void => {
    track(SEGMENT.events.phoneConversationInitiated, {
      page: currentPageKey,
      location: SEGMENT.locations.page,
    });
  };

  const onScheduleCallClicked = (): void => {
    track(SEGMENT.events.conversationSchedulingInitiated, {
      page: currentPageKey,
    });
  };

  if (isCustomerJourneyFetched && nextStepUrl) {
    return null;
  }

  if (isCustomerJourneyFetched && !nextStepUrl) {
    return (
      <>
        <CallAgentRibbon phoneNumber={phoneNumber} onPhoneClick={onPhoneClicked} />
        <div css={containerCSS}>
          <DigitalProfileNoQuotesContent
            noQuotesReason={NoQuoteMessageReason.personPolicyholder}
            onPhoneClicked={onPhoneClicked}
            onScheduleCallClick={onScheduleCallClicked}
          />
        </div>
      </>
    );
  }
};

export default PolicyHolderDigitalProfileQuotePage;
