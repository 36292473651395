import { useState } from 'react';
import ToggleArrow from '../../../../assets/toggle-arrow.svg?react';
import SIRV_IMAGES from '../../../../constants/sirv-images';
import useResponsive from '../../../../hooks/use-responsive';
import { isOtherIcon } from '../../../../utils/icons.util';
import { getImgUrlWithParams } from '../../SirvImage/SirvImage.util';
import { checkIconCSS } from '../Checkbox.style';
import CheckboxIcon from '../CheckboxIcon/CheckboxIcon';
import {
  contentCSS,
  descriptionCSS,
  labelContainerCSS,
  otherCSS,
  photoCSS,
  toggleCSS,
  labelCSS,
  checkboxIconCSS,
} from './CheckboxPhotoView.style';
import type { CheckboxPhotoViewProps } from './CheckboxPhotoView.type';
import type { FC } from 'react';

const CheckboxPhotoView: FC<CheckboxPhotoViewProps> = ({
  label,
  checked,
  name,
  disabled,
  labelDescription,
  hasSubElements,
  hasError,
  photoUrl,
}) => {
  const [isClosed, setIsClosed] = useState(true);
  const { isMobile } = useResponsive();
  const applyClosed = isMobile && isClosed;
  const sirvUrl = getImgUrlWithParams({ url: photoUrl ?? SIRV_IMAGES.photoPlaceholder, width: 500 });
  const withIcon = isOtherIcon(sirvUrl);

  return (
    <>
      <CheckboxIcon
        customCSS={checkIconCSS(
          hasError,
          'photoGrid',
          hasSubElements,
          Boolean(photoUrl),
          isMobile ? isClosed : undefined
        )}
        checked={!!checked}
        disabled={disabled}
        testId={name + '-checkbox-icon'}
      />
      <div css={contentCSS(applyClosed)}>
        {isMobile && !withIcon && (
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsClosed((v) => !v);
            }}
            css={toggleCSS(isClosed)}
          >
            <ToggleArrow />
          </button>
        )}
        {withIcon ? (
          <div css={otherCSS(applyClosed)}>
            <img css={[checkboxIconCSS]} src={sirvUrl} aria-hidden alt="" />
          </div>
        ) : (
          <div css={photoCSS(sirvUrl, applyClosed)} />
        )}
        <div css={labelContainerCSS(applyClosed)}>
          <div css={labelCSS}>{label}</div>
          {labelDescription && <div css={descriptionCSS(applyClosed)}>{labelDescription}</div>}
        </div>
      </div>
    </>
  );
};

export default CheckboxPhotoView;
