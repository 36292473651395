import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';

export const containerCSS = css`
  width: 100%;
`;

export const contentCSS = css`
  display: flex;
  flex-direction: column;
  padding: 0 24px 32px;
  gap: 32px;

  ${mq[0]} {
    flex-direction: row;
    gap: 56px;
    padding: 0 40px 48px;
  }
`;

export const contentColumnCSS = css`
  ${mq[0]} {
    width: 50%;
  }
`;

export const homeOnlyCSS = css`
  padding: 0 24px 24px;
  margin-left: -24px;
  margin-right: -24px;

  ${mq[0]} {
    margin: 0;
    padding: 20px;
  }
`;

export const numbersHeaderCSS = css`
  display: flex;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 24px;

  ${mq[0]} {
    height: 40px;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const logoContainerCSS = css`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 0 24px 24px;

  ${mq[0]} {
    position: relative;
    width: 100%;
    padding: 0 0 0 40px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const smallIconCSS = css`
  position: absolute;
  left: 0;
  width: 24px;
  height: 24px;
  color: ${theme.colors.gray60};
  display: none;

  ${mq[0]} {
    display: block;
  }
`;

export const carrierLogoCSS = css`
  min-width: 80px;
  justify-content: left;
  align-items: center;

  img {
    max-width: 120px;
    max-height: 40px;
  }
`;

export const savingsTagCSS = css`
  position: relative;
  display: inline-block;
  border-radius: 40px;
  background-color: ${theme.colors.positiveGreen94};
  padding: 6px 34px 6px 12px;
  margin-top: 12px;
  white-space: nowrap;
  line-height: 16px;

  ${mq[0]} {
    margin-top: 0;
  }
`;

export const savingsTagButtonCSS = css`
  position: absolute;
  right: 12px;
  color: ${theme.colors.positiveGreen27};
`;

export const iconCSS = css`
  width: 80px;
  height: 80px;
`;

export const houseIconCSS = css`
  ${iconCSS};
`;

export const policyIconContainerCSS = css`
  background: ${theme.colors.azure95};
  width: 50%;
  padding: 24px;
  text-align: right;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 240px;
    height: 240px;
    top: calc(50% - 120px);
    left: -218px;
    background-color: ${theme.colors.white};
    border-radius: 50%;
  }

  ${mq[0]} {
    display: none;
  }
`;

export const carIconCSS = css`
  ${iconCSS};
  transform: scaleX(-1);
`;

export const numbersCSS = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const dateWrapperCSS = css`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const dashedRowCSS = css`
  & > span:first-of-type {
    color: ${theme.colors.black};
  }

  & > span:last-of-type {
    font-weight: 700;
  }
`;

export const betterFitCSS = css`
  display: flex;
  margin-top: 8px;
  padding: 0;
  color: ${theme.colors.azure50};
  background-color: transparent;
  gap: 4px;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${theme.colors.azure68};
  }
`;

export const annualPremiumLabelCSS = css`
  font-weight: 400;
  color: ${theme.colors.black};
`;
export const annualPremiumTooltipButtonCSS = css`
  display: inline-flex;
  align-items: center;
  padding: 0;
  background-color: transparent;
  color: ${theme.colors.azure50};

  & > span {
    font-weight: 400;
    color: ${theme.colors.azure50};
  }

  & > svg {
    margin-left: 4px;
  }

  &:hover {
    color: ${theme.colors.azure68};

    & > span {
      color: ${theme.colors.azure68};
    }
  }
`;
