import { checkIconCSS, imageCSS, subtitleCSS, titleCSS } from '../Checkbox.style';
import CheckboxIcon from '../CheckboxIcon/CheckboxIcon';
import { verticalSubtitleCSS, verticalTitleContainerCSS, verticalTitleCSS } from './CheckboxVerticalNarrowView.style';
import type { CheckboxVerticalNarrowViewProps } from './CheckboxVerticalNarrowView.type';
import type { FC } from 'react';

const CheckboxVerticalNarrowView: FC<CheckboxVerticalNarrowViewProps> = ({
  label,
  checked,
  name,
  disabled,
  labelDescription,
  hasSubElements,
  hasError,
  icon,
  variant,
  alt,
}) => {
  return (
    <>
      <CheckboxIcon
        customCSS={checkIconCSS(hasError, variant, hasSubElements, Boolean(icon))}
        checked={checked}
        disabled={disabled}
        testId={name + '-checkbox-icon'}
      />

      <div>
        <div css={verticalTitleContainerCSS}>
          {icon && <img css={imageCSS(!!checked)} src={icon} width={48} height={48} alt={alt} />}
          <span css={[titleCSS, verticalTitleCSS]}>{label}</span>
        </div>
        {labelDescription && <span css={[subtitleCSS, verticalSubtitleCSS]}>{labelDescription}</span>}
      </div>
    </>
  );
};

export default CheckboxVerticalNarrowView;
