import type { HomeQuoteCoverage } from '../../../../../types/quote.type';
import type { QuoteCoveragesGroup } from '../../QuoteCoverageGroup/QuoteCoverageGroup.type';
import type { ReactNode } from 'react';

export const generateCoveragesGroup = (
  coverages: HomeQuoteCoverage[],
  groupTitle: ReactNode,
  hideTitle: boolean
): QuoteCoveragesGroup[] => [
  {
    assetGid: 'without_asset',
    title: hideTitle ? '' : groupTitle,
    coverages: coverages.map((el) => ({
      ...el,
      iconUrl: el.icon_url,
    })),
  },
];
