import type { PageKey } from '../../types/page.type';
import type { PolicyType } from '../../types/policy.type';
import type { SessionStatus } from '../../types/session.type';

export interface SessionInitState {
  isLoading: boolean;
  sessionGid?: string;
  sessionStatus?: SessionStatus;
  currentPageKey?: PageKey;
  policyType?: PolicyType;
}

export enum InitSessionSearchParams {
  PersonGid = 'person_gid',
  AssetGid = 'asset_gid',
  QuoteGid = 'quote_gid',
  EngagementGid = 'engagement_gid',
  testMode = 'test_mode',
  stubMode = 'stub_mode',
  dpSource = 'dp_source',
  dpMedium = 'dp_medium',
  dpCampaign = 'dp_campaign',
}
