import HouseIconSmall from '../../../../../assets/home-simple.svg?react';
import QuestionIcon from '../../../../../assets/question-mark-circle.svg?react';
import CarIcon from '../../../../../assets/quote-hero-car.svg?react';
import HouseIcon from '../../../../../assets/quote-hero-house.svg?react';
import CarIconSmall from '../../../../../assets/vehicle.svg?react';
import { AUTOMATION_TEST_ID } from '../../../../../constants/automation-test-id';
import DashedRow from '../../../../../shared/components/DashedRow/DashedRow';
import Logo from '../../../../../shared/components/Logo/Logo';
import Tooltip from '../../../../../shared/components/Tooltip/Tooltip';
import TooltipQuestionButton from '../../../../../shared/components/TooltipQuestionButton/TooltipQuestionButton';
import { HOME_POLICY_TYPES } from '../../../../../types/quote.type';
import format from '../../../../../utils/format.util';
import { getQuoteSavings } from '../../../../../utils/quote.util';
import HomeOnlyMessage from '../HomeOnlyMessage/HomeOnlyMessage';
import QuoteDetailsHero from '../QuoteDetailsHero/QuoteDetailsHero';
import {
  annualPremiumLabelCSS,
  annualPremiumTooltipButtonCSS,
  betterFitCSS,
  carIconCSS,
  carrierLogoCSS,
  containerCSS,
  contentColumnCSS,
  contentCSS,
  dashedRowCSS,
  homeOnlyCSS,
  houseIconCSS,
  logoContainerCSS,
  numbersCSS,
  numbersHeaderCSS,
  policyIconContainerCSS,
  savingsTagButtonCSS,
  savingsTagCSS,
  smallIconCSS,
} from './DigitalProfileBundleQuoteDetails.style';
import { AUTO_PREMIUM_TOOLTIP_CONTENT, getAnnualPremium } from './DigitalProfileBundleQuoteDetails.util';
import type { DigitalProfileBundleQuoteDetailsProps } from './DigitalProfileBundleQuoteDetails.type';
import type { FC } from 'react';

const DigitalProfileBundleQuoteDetails: FC<DigitalProfileBundleQuoteDetailsProps> = ({
  customCSS,
  initialQuote,
  effectivePolicy,
  quotes,
  quotesGroup,
  isCallScheduled,
  onScheduleCallClick,
}) => {
  const homeQuote = quotes.find((quote) => HOME_POLICY_TYPES.includes(quote.policy_type));
  const autoQuote = quotes.find((quote) => quote.policy_type === 'auto');
  const homeOnly = !autoQuote;

  const totalPremiumPerMonth = Math.round((Number(quotesGroup.total_premium) / 12) * 100) / 100;

  const totalDiscountPremiumPerMonth =
    Math.round((Number(quotesGroup.total_premium_with_multi_policy_discount) / 12) * 100) / 100;

  const mpdApplied = quotesGroup.multi_policy_discount;

  const homeDwelling = homeQuote?.coverages.find((coverage) => coverage.key === 'dwelling')?.value;
  const autoDeductible = autoQuote?.coverages.find((coverage) => coverage.key === 'comprehensive')?.value;
  const autoBodilyInjury = autoQuote?.coverages.find((coverage) => coverage.key === 'bodily_injury')?.value;

  const homePremium = getAnnualPremium(homeQuote, mpdApplied);
  const autoPremium = getAnnualPremium(autoQuote, mpdApplied);

  const savings = getQuoteSavings(homeQuote, mpdApplied, effectivePolicy);

  const premiumString = `$${format.money(Number(homePremium))}`;
  const currentPremiumString = `$${format.money(Number(homePremium) + savings)}`;

  const isBetterFit = initialQuote && homeQuote && initialQuote.carrier_key !== homeQuote.carrier_key;

  const savingsTooltipContent =
    '<p>This how much you can save if you switch your policies. We calculated ' +
    'savings based on the difference between your current premium and this quoted premium.</p>' +
    `<p><strong>${currentPremiumString} - ${premiumString} = $${format.money(savings)}</strong></p>`;

  const betterFitTooltipContent = (
    <span>
      This carrier’s quote was a <strong>better option</strong> for you than our previous
      {initialQuote?.carrier_name} quote of ${format.money(initialQuote?.premium ?? 0)}.
    </span>
  );

  const autoPremiumLabel = (
    <>
      <span css={annualPremiumLabelCSS}>Annual Premium </span>
      <Tooltip
        position="top"
        customCSS={{ buttonCSS: annualPremiumTooltipButtonCSS }}
        content={AUTO_PREMIUM_TOOLTIP_CONTENT}
      >
        <span>(Est.)</span>
        <QuestionIcon />
      </Tooltip>
    </>
  );

  return (
    <div css={[containerCSS, customCSS]}>
      <QuoteDetailsHero
        premium={totalPremiumPerMonth}
        discountPremium={totalDiscountPremiumPerMonth}
        homeOnly={homeOnly}
        actionButtonLabel={isCallScheduled ? 'Appointment details' : 'Schedule a call'}
        onActionClick={onScheduleCallClick}
      />
      <div css={contentCSS}>
        <div css={contentColumnCSS}>
          <div css={numbersHeaderCSS}>
            <div css={logoContainerCSS}>
              <HouseIconSmall css={smallIconCSS} />
              <Logo
                carrierLogo={homeQuote?.carrier_logo_url}
                carrierName={homeQuote?.carrier_name}
                customCSS={carrierLogoCSS}
                height={80}
              />
              <div>
                {savings > 0 && (
                  <div data-testid={AUTOMATION_TEST_ID.bundleQuotePage.homeSavings} css={savingsTagCSS}>
                    Savings ${format.money(savings)}
                    <TooltipQuestionButton customCSS={savingsTagButtonCSS} size={16} content={savingsTooltipContent} />
                  </div>
                )}
              </div>
            </div>
            <div css={policyIconContainerCSS}>
              <HouseIcon css={houseIconCSS} />
            </div>
          </div>
          <div css={numbersCSS}>
            <DashedRow
              testId={AUTOMATION_TEST_ID.bundleQuotePage.homeAnnualPremium}
              customCSS={dashedRowCSS}
              label="Annual Premium"
              content={`$${format.money(homePremium)}`}
            />
            <DashedRow
              testId={AUTOMATION_TEST_ID.bundleQuotePage.homeDwelling}
              customCSS={dashedRowCSS}
              label="Dwelling Coverage"
              content={homeDwelling}
            />
            <DashedRow
              testId={AUTOMATION_TEST_ID.bundleQuotePage.homeDeductible}
              customCSS={dashedRowCSS}
              label="Deductible"
              content={`$${format.money(homeQuote?.deductible ?? 0)}`}
            />

            {isBetterFit && (
              <div>
                <Tooltip position="top" customCSS={{ buttonCSS: betterFitCSS }} content={betterFitTooltipContent}>
                  <span>This home carrier was a better fit</span>
                  <QuestionIcon />
                </Tooltip>
              </div>
            )}
          </div>
        </div>

        <div css={contentColumnCSS}>
          {homeOnly ? (
            <HomeOnlyMessage
              customCSS={homeOnlyCSS}
              callIsScheduled={isCallScheduled}
              onActionClick={onScheduleCallClick}
            />
          ) : (
            <>
              <div css={numbersHeaderCSS}>
                <div css={logoContainerCSS}>
                  <CarIconSmall css={smallIconCSS} />
                  <Logo
                    carrierLogo={autoQuote?.carrier_logo_url}
                    carrierName={autoQuote?.carrier_name}
                    customCSS={carrierLogoCSS}
                    height={80}
                  />
                </div>
                <div css={policyIconContainerCSS}>
                  <CarIcon css={carIconCSS} />
                </div>
              </div>
              <div css={numbersCSS}>
                <DashedRow
                  testId={AUTOMATION_TEST_ID.bundleQuotePage.autoAnnualPremium}
                  customCSS={dashedRowCSS}
                  label={autoPremiumLabel}
                  content={`$${format.money(autoPremium)}`}
                />
                <DashedRow
                  testId={AUTOMATION_TEST_ID.bundleQuotePage.autoLiability}
                  customCSS={dashedRowCSS}
                  label="Bodily Injury Liability"
                  content={autoBodilyInjury}
                />
                <DashedRow
                  testId={AUTOMATION_TEST_ID.bundleQuotePage.autoDeductible}
                  customCSS={dashedRowCSS}
                  label="Comprehensive Deductible"
                  content={autoDeductible}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DigitalProfileBundleQuoteDetails;
