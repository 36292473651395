import api from '../../api/api';
import API_PATH from '../../constants/api';
import { compactObject } from '../../shared/utils/object-helpers.util';
import { getApiUrl } from '../../utils/api.util';
import type { HTTPResponse } from '../../types/api.type';
import type { SessionResponse } from '../../types/session.type';

interface SessionInitParams {
  personGid: string;
  assetGid: string;
  engagementGid?: string;
  quoteGid?: string;
  testMode: boolean;
  stubMode: boolean;
  apiClient?: string;
  dpSource?: string;
  dpMedium?: string;
  dpCampaign?: string;
}

const sessionService = {
  async initSession({
    personGid,
    assetGid,
    engagementGid,
    quoteGid,
    testMode = false,
    stubMode = false,
    dpSource,
    dpMedium,
    dpCampaign,
  }: SessionInitParams): Promise<SessionResponse> {
    return await api
      .post<HTTPResponse<SessionResponse>>(getApiUrl(API_PATH.sessionInit), {
        body: {
          data: compactObject({
            person_gid: personGid,
            asset_gid: assetGid,
            quote_gid: quoteGid,
            engagement_gid: engagementGid,
            test_mode: testMode,
            stub_mode: stubMode,
            dp_source: dpSource,
            dp_medium: dpMedium,
            dp_campaign: dpCampaign,
          }),
        },
      })
      .then((res) => res.data);
  },
  async getSession(gid: string): Promise<SessionResponse> {
    return await api.get<HTTPResponse<SessionResponse>>(getApiUrl(API_PATH.session, gid)).then((res) => res.data);
  },
};

export default sessionService;
