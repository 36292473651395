import type { PolicyType } from '../types/policy.type';

const getFSCurrentSessionURL = (): string | null => {
  if (!(window as any).FS?.getCurrentSessionURL || typeof (window as any).FS.getCurrentSessionURL !== 'function') {
    return null;
  }
  return (window as any).FS?.getCurrentSessionURL(true);
};

const analytics = {
  init: (segmentKey: string): void =>
    (window as any).analytics?.initialized || (window as any).analytics?.load(segmentKey),
  identify: (...args: unknown[]): void => (window as any).analytics?.identify(...args),
  track: (eventName: string, gid?: string, policyType?: PolicyType, config: Record<string, unknown> = {}): void => {
    const configWithGeneric = { session_gid: gid, flow_type: policyType, ...config };
    return (window as any).analytics?.track(eventName, configWithGeneric);
  },
  getFSUrl: (): Promise<string | null> => {
    return new Promise((resolve) => {
      const timeout = setTimeout(() => {
        resolve(null);
      }, 10000);

      const url = getFSCurrentSessionURL();

      if (url) {
        clearTimeout(timeout);
        resolve(url);
      } else {
        (window as any)._fs_ready = () => {
          const readyUrl = getFSCurrentSessionURL();
          clearTimeout(timeout);
          resolve(readyUrl);
        };
      }
    });
  },
  generateTrackEmail: (personGid: string): string => {
    return `${personGid}@matic.gid`;
  },
  page: (pageName: string, ...args: unknown[]): void => (window as any).analytics?.page(pageName, ...args),
};

export default analytics;
