import { Controller } from 'react-hook-form';
import { OTHER_STRUCTURES_NONE_OPTION_KEY } from '../../../../constants/schema-hardcoded-keys';
import { CHECKBOX_VALUE } from '../../../../questionsForm/components/Question/CheckboxQuestion/CheckboxInput/CheckboxInput.util';
import { containerCSS as questionWrapperCSS } from '../../../../questionsForm/components/QuestionWrapper/QuestionWrapper.style';
import { isValueSpecificType } from '../../../../questionsForm/utils/answers/answers.util';
import noop from '../../../utils/noop';
import Checkbox from '../Checkbox';
import { containerCSS, selectCSS } from './MultiSelectCheckbox.style';
import type { MultiSelectCheckboxProps } from './MultiSelectCheckbox.type';
import type { FC } from 'react';

const MultiSelectCheckbox: FC<MultiSelectCheckboxProps> = ({
  customCSS,
  inputId,
  options,
  hasError,
  value,
  onValidEntry = noop,
  isDisabled = false,
  onValidatePreviousInputs = noop,
}) => {
  if (!options) {
    throw new Error('MultiSelect Component: options must be provided.');
  }

  const multiSelectValues = value.filter((el) => el !== '');

  const optionsWithKey = options.map((option) => ({ ...option, name: `${inputId}_${option.value}` }));

  const getOptionValue = (option: string): string => option.split('_').pop() || '';
  const onUpdateSelect = (name: string): void => {
    const optionValue = getOptionValue(name);
    const newValues = multiSelectValues.includes(optionValue)
      ? multiSelectValues.filter((value) => value !== optionValue)
      : [...multiSelectValues, optionValue];

    const newValuesWithoutNone = newValues.filter((value) => value !== OTHER_STRUCTURES_NONE_OPTION_KEY);
    onValidEntry(newValuesWithoutNone.length > 0 ? newValuesWithoutNone : [OTHER_STRUCTURES_NONE_OPTION_KEY]);
  };

  return (
    <>
      <input type="select" css={selectCSS} name={inputId} data-testid={inputId + '-multi-select-input'} />
      <div css={containerCSS}>
        {optionsWithKey.map((option) => (
          <div
            key={option.name}
            css={[questionWrapperCSS(isDisabled, 4), customCSS]}
            data-testid={`question-input-${inputId}`}
          >
            <Controller
              name={option.name}
              defaultValue={
                multiSelectValues.includes(getOptionValue(option.name))
                  ? CHECKBOX_VALUE.checked
                  : CHECKBOX_VALUE.unchecked
              }
              render={({ field }) => {
                const inputValue = isValueSpecificType<string | string[] | number>(field.value, [
                  'string',
                  'array',
                  'number',
                ])
                  ? field.value
                  : CHECKBOX_VALUE.unchecked;
                return (
                  <Checkbox
                    {...field}
                    label={option.label}
                    labelDescription={option.description}
                    icon={option.icon_url}
                    disabled={isDisabled}
                    hasError={hasError}
                    checked={field.value === CHECKBOX_VALUE.checked}
                    value={inputValue}
                    variant={'photoGrid'}
                    onChange={(event) => {
                      field.onChange(event.target.checked ? CHECKBOX_VALUE.checked : CHECKBOX_VALUE.unchecked);
                      onUpdateSelect(event.target.name);
                      onValidatePreviousInputs();
                    }}
                    onBlur={field.onBlur}
                  />
                );
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default MultiSelectCheckbox;
