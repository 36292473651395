import { useState, type FC, type ReactNode } from 'react';
import Button from '../../../../../shared/components/Button/Button';
import Tabs from '../../../../../shared/components/Tabs/Tabs';
import { PolicyType } from '../../../../../types/policy.type';
import { getCoveragesGroups } from '../../../../../utils/coverages.util';
import DigitalProfileQuoteCoverages from '../DigitalProfileQuoteCoverages/DigitalProfileQuoteCoverages';
import {
  autoCoveragesCSS,
  buttonCSS,
  coveragesCSS,
  coveragesContainerCSS,
  coveragesInsideTabsCSS,
  policyCoveragesTypeCSS,
  tabsContainerCSS,
} from './DigitalProfileBundleQuoteCoverages.style';
import type { DigitalProfileBundleQuoteCoveragesProps } from './DigitalProfileBundleQuoteCoverages.type';
import type { AutoQuote, AutoQuoteCoverage, DigitalProfileQuote, QuoteAsset } from '../../../../../types/quote.type';

const DigitalProfileBundleQuoteCoverages: FC<DigitalProfileBundleQuoteCoveragesProps> = ({ customCSS, quotes }) => {
  const [activeType, setActiveType] = useState(PolicyType.Home);
  const homeQuote = quotes.find((q) => q.policy_type === PolicyType.Home);
  const autoQuote = quotes.find((q) => q.policy_type === PolicyType.Auto);

  const isBundle = homeQuote && autoQuote;

  const isAutoQuote = (quote: DigitalProfileQuote): quote is DigitalProfileQuote & AutoQuote =>
    quote.policy_type === PolicyType.Auto;

  const getAutoCoveragesTabs = (
    coverages: AutoQuoteCoverage[],
    assets: QuoteAsset[]
  ): Array<{ label: ReactNode; content: ReactNode }> => {
    const coverageGroups = getCoveragesGroups(coverages, assets);

    return coverageGroups.map((cg) => ({
      label: cg.title,
      content: (
        <DigitalProfileQuoteCoverages
          customCSS={{ ...coveragesInsideTabsCSS, ...autoCoveragesCSS }}
          hideTitle
          coverages={cg.coverages.map((c) => ({
            ...c,
            key: cg.assetGid,
            asset_gid: cg.assetGid,
          }))}
        />
      ),
    }));
  };

  return (
    <div css={[customCSS]}>
      {isBundle && (
        <div css={policyCoveragesTypeCSS}>
          <Button
            customCSS={[buttonCSS(activeType === PolicyType.Home)]}
            onClick={() => setActiveType(PolicyType.Home)}
          >
            Home Policy
          </Button>
          <Button
            customCSS={[buttonCSS(activeType === PolicyType.Auto)]}
            onClick={() => setActiveType(PolicyType.Auto)}
          >
            Auto Policy
          </Button>
        </div>
      )}
      <div css={coveragesContainerCSS}>
        {activeType === PolicyType.Home && (
          <DigitalProfileQuoteCoverages customCSS={coveragesCSS} hideTitle coverages={homeQuote?.coverages ?? []} />
        )}
        {activeType === PolicyType.Auto && autoQuote && isAutoQuote(autoQuote) && (
          <Tabs
            customCSS={tabsContainerCSS}
            tabs={getAutoCoveragesTabs(autoQuote.coverages, autoQuote?.assets ?? [])}
          />
        )}
      </div>
    </div>
  );
};

export default DigitalProfileBundleQuoteCoverages;
