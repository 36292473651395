import { Controller } from 'react-hook-form';
import { OTHER_OPTION_KEY } from '../../../../constants/schema-hardcoded-keys';
import MultiSelectCheckbox from '../../../../shared/components/Checkbox/MultiSelectCheckbox/MultiSelectCheckbox';
import MultiSelect from '../../../../shared/components/Select/MultiSelect';
import { FormElementTag } from '../../../../types/form-element.type';
import { getValidationRules } from '../../../utils/question-validations/question-validations.util';
import QuestionWrapper from '../../QuestionWrapper/QuestionWrapper';
import type { SpecificQuestionProps } from '../Question.type';
import type { FC } from 'react';

const MultiSelectQuestion: FC<SpecificQuestionProps> = ({
  questionKey,
  label,
  hideLabel,
  placeholder,
  description,
  hint,
  icon,
  workflowHeading,
  validations,
  variants,
  isDisabled,
  gridWidth,
  dense,
  tags,
}) => {
  const isMultiSelectCheckbox = tags?.includes(FormElementTag.MultiSelectCheckbox);

  return (
    <Controller
      name={questionKey}
      rules={getValidationRules(validations)}
      render={({ field: { onChange, value, onBlur }, fieldState: { isTouched } }) => {
        const onSelectChange = (v: string): void => {
          onChange(v);
        };

        return (
          <QuestionWrapper
            questionKey={questionKey}
            label={label}
            hideLabel={hideLabel}
            hint={hint}
            description={description}
            icon={icon}
            workflowHeading={workflowHeading}
            isDisabled={isDisabled}
            gridWidth={gridWidth}
            inputProps={{
              inputId: questionKey,
              value: Array.isArray(value) ? value : [value],
              placeholder,
              options: variants,
              isDisabled,
              isPrefilled: Boolean(value && !isTouched),
              isSearchable: true,
              dense,
              otherOptionKey: tags?.includes(FormElementTag.ShowOtherOptionAlways) ? OTHER_OPTION_KEY : null,
              onBlur,
              onValidEntry: onSelectChange,
            }}
            inputComponent={isMultiSelectCheckbox ? MultiSelectCheckbox : MultiSelect}
          />
        );
      }}
    />
  );
};

export default MultiSelectQuestion;
