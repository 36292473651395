import type { Validation } from './validation.type';
import type { VisibilityCondition } from './visibility-condition.type';

export interface QuestionVariant {
  label: string;
  value: string | number;
  description?: string | null;
  icon_url?: string | null;
}

export enum QuestionType {
  Text = 'text',
  Number = 'number',
  Date = 'date',
  Address = 'address',
  Phone = 'phone',
  Email = 'email',
  SingleSelect = 'single_select',
  MultiSelect = 'multi_select',
  Radio = 'radio',
  Checkbox = 'checkbox',
  Counter = 'counter',
  Switch = 'switch',
  VehicleSelect = 'vehicle_select',
  /** Checkout only Question types */
  CardNumber = 'card-number',
  CardExpirationDate = 'card-expiration-date',
  CardSecurityCode = 'card-security-code',
}

export interface QuestionSchema {
  key: string;
  label: string;
  description?: string;
  hint?: string;
  type: QuestionType;
  placeholder?: string;
  icon?: string;
  variants?: QuestionVariant[];
  validations?: Validation[];
  visibility_conditions: VisibilityCondition[][];
}
