import { FormElementTag } from '../../../../types/form-element.type';
import { QuestionType } from '../../../../types/question.type';
import { HOME_POLICY_TYPES } from '../../../../types/quote.type';
import {
  getAutoPolicyEffectiveDateComponent,
  getHomePolicyEffectiveDateComponent,
} from '../../../../utils/schema.util';
import type { CustomSectionSchema } from '../../../../types/section.type';
import type { QuoteDetailsQuestion } from '../HomeQuoteOpportunityPage/QuoteDetailsEditButton/QuoteDetailsEditButton.type';

export const getEffectiveDateQuestionToRender = (
  effectiveDate: string,
  policyType: string,
  sections?: CustomSectionSchema[]
): QuoteDetailsQuestion | null => {
  const homeEffectiveDateComponent = getHomePolicyEffectiveDateComponent(sections);
  const autoEffectiveDateComponent = getAutoPolicyEffectiveDateComponent(sections);
  const isHomePolicy = HOME_POLICY_TYPES.includes(policyType);

  const component = isHomePolicy ? homeEffectiveDateComponent : autoEffectiveDateComponent;
  if (!component) {
    return null;
  }

  return {
    questionKey: component?.key,
    type: QuestionType.Date,
    label: component?.label,
    validations: component?.validations,
    dense: true,
    tags: [FormElementTag.DatePicker, FormElementTag.RenderDropDownInBody],
    answer: effectiveDate,
  };
};
