import { PolicyType } from '../types/policy.type';
import type { EffectivePolicy } from '../types/policy.type';
import type { QuoteType, Quote, QuotesGroup, HomeQuote, AutoQuote } from '../types/quote.type';

export const getPrimaryQuote = <T extends QuoteType>(
  quotes: T[] = [],
  policyType?: PolicyType,
  carrierKey?: string
): T | null => quotes.find((quote) => quote.policy_type === policyType && quote.carrier_key === carrierKey) ?? null;

export const isAutoQuote = (quote: QuoteType): quote is AutoQuote => quote.policy_type === PolicyType.Auto;

export const isHomeQuote = (quote: QuoteType): quote is HomeQuote => quote.policy_type === PolicyType.Home;

export const isBundleQuotesGroup = (quotesGroup?: QuotesGroup): boolean => {
  if (!quotesGroup) {
    return false;
  }

  if (quotesGroup.quotes.length === 1) {
    return false;
  }

  const hasAutoQuote = quotesGroup.quotes.some((quote) => quote.policy_type === 'auto');
  const hasHomeQuote = quotesGroup.quotes.some((quote) => quote.policy_type === 'home');

  return !!quotesGroup.multi_policy_discount && hasAutoQuote && hasHomeQuote;
};

export const isBundleQuotesGroupBindable = (quotesGroup?: QuotesGroup): boolean => {
  if (!quotesGroup) {
    return false;
  }

  if (!isBundleQuotesGroup(quotesGroup)) {
    return false;
  }

  return quotesGroup.quotes.every((quote) => quote.bindable);
};

export const getQuoteSavings = (quote?: Quote, withMpd?: boolean, effectivePolicy?: EffectivePolicy): number => {
  if (!quote || !effectivePolicy?.premium) {
    return 0;
  }

  let savings = 0;

  if (withMpd && quote.multi_policy_discount_premium) {
    savings = Number(effectivePolicy.premium) - Number(quote.multi_policy_discount_premium);
  } else if (quote.premium) {
    savings = Number(effectivePolicy.premium) - Number(quote.premium);
  }

  return savings >= 10 ? savings : 0;
};
