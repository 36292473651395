import type { AddressAnswer } from '../../../../../types/answer.type';
import type { AutoQuote, HomeQuote } from '../../../../../types/quote.type';

export interface BundleQuoteCoveragesProps {
  homeQuote: HomeQuote;
  autoQuote: AutoQuote;
  address: AddressAnswer;
}

export enum TabActiveType {
  HOME = 'home',
  AUTO = 'auto',
}
